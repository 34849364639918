.updatesHeader {
	text-align: center;
	padding-bottom: 30px;
	color: #0050f0;
}

.updatesRow {
	margin: 30px 0 60px 0;
}

.updatesWindow {
	width: 100%;
	height: 100vh;
}

.updatesSignupText {
	font-size: 16px;
}

/* Google Docs Section */

.iframeDiv {
	border: 1px solid #343434;
	padding: 60px;
}

@media (max-width: 768px) {
	.iframeDiv {
		padding: 0;
	}
}

.blurDiv {
	z-index: 999;
	height: 75vh;
	position: absolute;
	width: 100%;
	left: 0;
	bottom: 0;
	backdrop-filter: blur(10px);
}

.updatesSignupDiv {
	text-align: center;
	z-index: 999;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-color: #0050f0;
	padding: 30px;
	color: #ffffff;
	width: 100%;
}

.updatesSignupButton {
	background-color: transparent;
	color: #ffffff;
	border: 1px solid #ffffff;
	margin: 0 15px 0 auto !important;
	border-radius: 0;
	font-size: 16px;
	padding: 15px 0;
	transition-duration: 0.4s;
	width: 200px;
}

.updatesSignupButton:hover {
	color: #0050f0 !important;
	background-color: #ffffff !important;
	transition-duration: 0.4s;
}
