/* Form Inputs */

*,
*:before,
*:after {
	box-sizing: border-box;
	position: relative;
}

.inputRow {
	background: rgba(246, 247, 249, 0.7);
	padding: 15px 15px 0 15px;
	border: 1px solid #e8e8e8;
	box-sizing: border-box;
	border-radius: 10px;
	margin-bottom: 15px;
}

@media (max-width: 768px) {
	.inputRow {
		padding: 15px 0 0 0;
	}
}

.inputCol {
	/* padding: 20px 0 !important; */
	padding-bottom: 15px;
}

/* @media (max-width: 768px) {
  .inputCol {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
} */

.inputFormButtonCol {
	padding-left: 0 !important;
	padding-right: 0 !important;
}

.inputSelectCol {
	padding: 5px 0 20px 0 !important;
}

.inputStandardLabelCol {
	margin: 0;
}

.inputStandardLabel {
	color: rgba(23, 27, 32, 0.5);
	font-weight: 500 !important;
	margin: 5px 0 0 0;
	font-size: 14px;
}

.inputStandardCol {
	margin-top: 3px;
	padding-bottom: 15px;
}

.checkInputCol {
	padding: 0 15px 10px 15px;
}

/* Form Inputs */

.formInputs, .inputCol select {
	margin: auto;
	width: 100%;
	padding: 15px;
	border-radius: 4px;
	font-weight: normal;
	background: #353a7a;
	color: var(--bs-white);
	border: 1px solid  #353a7a;
	font-family: 'Avenir Next Cyr';
	appearance: none !important;
	-webkit-appearance: none !important;
}
.formInputs:focus, .inputCol select:focus {
	border-color: #8CA1ED;
	background: #3A4099;
}
.inputCol select {  
	background-image: url(img/down_icon.svg);
	background-repeat: no-repeat;
	background-position: center right 15px;
	background-size: 14px;
	outline: 0!important;
}
.inputCol select:disabled{ 
	opacity: .6;
}

.formInputs:disabled {
	background: #353a7a !important;
	cursor: not-allowed !important;
}

.formInputsSelect {
	width: 100%;
	padding: 17px;
	background: #353a7a;
	border: 1px solid #000000;
	box-sizing: border-box;
	border-radius: 6px;
	outline: none;
	color: #ffffff;
	font-size: 14px;
	caret-color: #353a7a;
	font-weight: 400;
	-webkit-appearance: none !important;
	background-image: url("data:image/svg+xml;utf8,<svg fill='%237034c6' height='44' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
	background-repeat: no-repeat;
	background-position-x: 100%;
	background-position-y: 5px;
}

.inputFieldDatePicker {
	width: 100%;
	border: none;
	border-bottom: 1px solid #353a7a;
	background: transparent;
	outline: none;
	color: #000000;
	font-size: 20px;
	caret-color: #353a7a;
}

.react-datepicker-wrapper {
	width: 100%;
	margin-top: -30px;
}

.formInputsLabel {
	transition-duration: 0.4s;
	font-weight: lighter !important;
	font-size: 16px;
	font-family: 'Avenir Next Cyr';
	font-weight: 500!important;
	padding-bottom: 5px;
	text-align: left;
	color: #ccdaf9;
	width: 100%;
}

.formInputs::placeholder {
	color: #ccdaf9;
	font-size: 16px;
	font-weight: 500 !important;
	opacity: 0.5;
	font-family: 'Avenir Next Cyr';
}

.formInputs:focus::-webkit-input-placeholder {
	opacity: 0;
}

.formInputs:focus,
.inputFieldDatePicker:focus {
	outline: none;
	/* border: none;
  border-bottom: 3px solid #353a7a; */
}

.formInputs:hover,
.inputFieldDatePicker:hover {
	outline: none;
	/* border: none; */
	/* border-bottom: 3px solid #353a7a; */
}

.formInputsTextArea {
	width: 100%;
	padding: 15px;
	border-radius: 7.5px;
	/* height: 150px; */
	border: 1px solid #353a7a;
	margin-top: 20px;
	outline: none;
	color: #171b20;
}

.formInputsTextArea::placeholder {
	color: rgba(23, 27, 32, 0.5);
}

.formInputsTextArea:focus,
.formInputsTextArea:hover {
	border: 1px solid #353a7a !important;
}

.formInputError {
	color: red;
	font-size: 14px !important;
	font-weight: 400 !important;
	margin: 5px 0 0 0;
	text-align: left;
}

.submitButton {
	float: right;
}

.submitButton:disabled {
	cursor: not-allowed;
}

/* Select */

.selectLabel {
	/* position: "absolute",
  left: !customLabel && "0px",
  top: !isFocused && !standardLabel ? 40 : 5, */
	font-size: 14px;
	padding-bottom: 0;
	color: #b5bbce;
	text-transform: uppercase;
	font-weight: 400;
	margin: 0 !important;
}

/* Checkbox */

.checkboxInputCol {
	margin-top: -15px;
	/* z-index: 999; */
}

.checkboxLabel {
	position: absolute;
	left: 0;
	top: -5 !important;
	/* font-size: 14 !important; */
	color: #b5bbce !important;
	transition-duration: 0.4s;
	font-weight: 700 !important;
	text-transform: 'uppercase';
}

.checkboxDiv {
	/* display: flex;
	flex-wrap: wrap; */
	padding-top: 30px;
}

/* The container */
.checkboxContainer {
	display: block;
	position: relative;
	padding-left: 35px;
	/* margin-bottom: 10px; */
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	font-weight: 400;
	font-size: 14px;
	line-height: 24px;
}

/* Hide the browser's default checkbox */
.checkboxContainer input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
}

/* Create a custom checkbox */
.checkmark {
	position: absolute;
	top: 2.5px;
	left: 0;
	height: 20px;
	width: 20px;
	background-color: #ffffff;
	border: 2px solid #d5d5d5;
	border-radius: 4px;
	transition-duration: 0.4s;
}

/* On mouse-over, add a grey background color */
.checkboxContainer:hover input ~ .checkmark {
	border: 2px solid #353a7a;
	transition-duration: 0.4s;
}

/* When the checkbox is checked, add a blue background */
.checkboxContainer input:checked ~ .checkmark {
	border: 2px solid #353a7a;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
	content: '';
	position: absolute;
	display: none;
}

/* Show the checkmark when checked */
.checkboxContainer input:checked ~ .checkmark:after {
	display: block;
}

/* Style the checkmark/indicator */
.checkboxContainer .checkmark:after {
	left: 5px;
	top: 0px;
	width: 6px;
	height: 13px;
	border: solid #353a7a;
	border-width: 0 2px 2px 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
}

.checkboxRow {
	width: 100%;
}

.checkboxLabelLink {
	text-decoration: none !important;
	color: #353a7a !important;
	font-weight: 700 !important;
}

/* Radio Button */

/* The container */
.radioContainer {
	display: block;
	position: relative;
	padding-left: 25px;
	/* margin-bottom: 12px; */
	cursor: pointer;
	font-size: 14px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

/* Hide the browser's default radio button */
.radioContainer input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
}

/* Create a custom radio button */
.radioCheckmark {
	position: absolute;
	top: 0;
	left: 0;
	height: 16px;
	width: 16px;
	background-color: transparent;
	border: 2px solid #949697;
	border-radius: 50%;
	margin-top: 2.5px;
}

/* On mouse-over, add a grey background color */
.radioContainer:hover input ~ .radioCheckmark {
	/* background-color: #000000;
  opacity: 0.1;
  transition-duration: 0.4s; */
}

/* When the radio button is checked, add a blue background */
.radioContainer input:checked ~ .radioCheckmark {
	background-color: #ffffff;
	border: 1px solid #353a7a;
	font-weight: 900;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.radioCheckmark:after {
	content: '';
	position: absolute;
	display: none;
}

/* Show the indicator (dot/circle) when checked */
.radioContainer input:checked ~ .radioCheckmark:after {
	display: block;
}

/* Style the indicator (dot/circle) */
.radioContainer .radioCheckmark:after {
	top: 2px;
	left: 2px;
	width: 10px;
	height: 10px;
	border-radius: 50%;
	background: #353a7a;
}

.radioInputCol {
	padding: 10px 0 20px 0 !important;
}

/* Dropzone Section */

.dzu-dropzone {
	/* Old */
	/* height: 50px;
  border: 1px solid #b5bbce !important; */

	/* New */
	background-color: #ffffff !important;
	border: 1px solid #0050f0 !important;
	border-radius: 0 !important;
	width: 200px !important;
	padding: 20px 0 !important;
	transition-duration: 0.4s;
	margin: 0 !important;
	height: 100% !important;
	min-height: 100% !important;
	overflow: -moz-hidden-unscrollable !important;
}

.dzu-dropzone:hover {
	/* background-color: #0050f0 !important;
  color: #ffffff !important;
  transition-duration: 0.4s; */
}

.dzu-dropzone::-webkit-scrollbar {
	display: none;
}

.dzu-inputLabelWithFiles,
.dzu-inputLabel {
	/* Old */
	/* color: #b5bbce !important; */

	/* New */
	color: #0050f0 !important;
	font-size: 16px !important;
}

@media (max-width: 768px) {
	.dzu-inputLabel {
		padding: 0 15px;
	}
}

.fileButtonDiv {
	display: flex;
	align-items: baseline;
}

.fileCloseButton {
	background: none !important;
	border: none !important;
	color: #0050f0 !important;
	padding: 0 !important;
	margin-left: 60px !important;
	margin-top: -10px !important;
	font-size: 18px !important;
	font-weight: 700 !important;
	transition-duration: 0.4s;
}

.fileCloseButton:hover {
	background-color: #ffffff !important;
	color: #0050f0 !important;
	transition-duration: 0.4s;
}

.fileCloseButton:disabled {
	cursor: not-allowed;
}

.filesHeader {
	font-size: 14px;
	margin-bottom: 20px;
}

/* HTML Editor */

.public-DraftEditor-content[contenteditable='true'] {
	border: 1px solid #b5bbce !important;
	border-radius: 5px;
}

.rdw-editor-toolbar {
	border: 1px solid #b5bbce !important;
}

/* Phone Input */

.PhoneInputInput {
	flex: 1 1;
	min-width: 0;
	border: none !important;
	outline: none !important;
}

/* Tooltip */

.tooltipIcon {
	margin-left: 5px;
	margin-top: -2px;
	width: 15px;
	height: 15px;
}

.tooltipIcon:hover {
	cursor: pointer;
}

.tooltipBody {
	background: #182242;
	border-radius: 10px;
}

.tooltipBody > .tooltip-inner {
	background: none;
	font-size: 11px;
	font-weight: 400;
}

/* Show Password Eye Icon */

.showPasswordIcon {
	position: absolute;
	top: 45px;
	right: 15px;
}
