.alertDiv {
	position: fixed !important;
	width: 100%;
	text-align: center;
	opacity: 1 !important;
	padding: 30px 0 !important;
	font-size: 20px;
	z-index: 1060 !important;
	color: #ffffff !important;
	display: flex;
}

.alertDivSuccess {
	background-color: green !important;
	/* background-color: #0050f0 !important; */
}

.alertDivError {
	background-color: red !important;
}

.alertText {
	text-align: center;
	margin: auto;
}

.alertCloseBtn {
	background: none !important;
	border: none !important;
	border-radius: 0 !important;
	margin: auto 30px auto -30px !important;
	box-shadow: none !important;
}

.alertCloseImg {
	width: 20px;
	height: auto;
}
