/* How It Works Section */

@media (min-width: 1200px) {
	.mobileUserheader {
		display: none;
	}
}

@media (max-width: 1199px) {
	.desktopUserheader {
		display: none;
	}
	.mobileUserheader {
		border-top: 1px solid rgba(112, 112, 112, 0.16);
	}
}

.dashboardSportsNameHeader {
	text-align: center;
	margin: -30px 0 0 0;
	color: #0050f0;
}

.dashboardHowItWorksHeader {
	/* text-align: left; */
	margin: 0;
	padding: 30px 0;
}

.howItWorksButtonDiv {
	padding-top: 30px;
}

.dashboardPackagePlan {
	margin: 0;
	text-align: center;
	margin-bottom: 0;
}

@media (max-width: 1199px) {
	.dashboardHowItWorksHeader {
		padding: 30px 0;
		text-align: center;
	}
}

.dashboardHowItWorksCol {
	padding-bottom: 60px !important;
}

.dashboardHowItWorksDiv {
	text-align: center;
	padding-top: 30px;
}

.dashboardHowItWorksImg {
	width: 30%;
	height: auto;
}

.dashboardSampleGame {
	width: 100%;
	height: auto;
}

.dashboardSampleGameMobile {
	width: 100%;
	height: auto;
	margin-bottom: 30px;
}

.dashboardSampleGameCol {
	padding: 30px;
}

@media (max-width: 768px) {
	.dashboardSampleGameCol {
		padding: 0;
	}
}

.dashboardSampleGameMobile {
	width: 100%;
	height: auto;
	margin-bottom: 30px;
	/* margin: auto; */
	max-width: 400px;
	display: block;
}

@media (min-width: 1200px) {
	.dashboardSampleGameCol {
		display: none;
	}
}

.dashboardSampleGameMobile {
	width: 100%;
	height: auto;
	margin-bottom: 30px;
}

@media (max-width: 1199px) {
	.dashboardSampleGame {
		display: none;
	}
}

@media (max-width: 768px) {
	.dashboardHowItWorksImg {
		width: 100%;
		height: auto;
	}
}

/* Introductory Header */

.introHeaderDiv {
	/* width: 55%; */
	margin: auto;
}

@media (max-width: 1200px) {
	.introHeaderDiv {
		width: 100%;
	}
}

.introHeader {
	text-align: left;
	font-size: 20px;
}

/* Legend Section */

@media (min-width: 768px) {
	.mobileLegendCol {
		display: none !important;
	}
}

@media (max-width: 768px) {
	.desktopLegendCol {
		display: none !important;
	}
}

@media (min-width: 768px) {
	.desktopLegendCol {
		padding-top: 0 !important;
		margin-top: 0 !important;
	}
}

/* .dashboardLegendsMainDiv {
  margin: auto;
  max-width: 300px !important;
} */

.dashboardLegendsMainDiv {
	margin: auto;
	width: 85%;
}

.dashboardLegendsMainRow {
	margin: auto;
}

@media (max-width: 768px) {
	.dashboardLegendsMainDiv {
		padding: 0;
	}
}

.dashboardHowItWorksRow {
	width: 85% !important;
	margin: auto !important;
	display: none !important;
}

@media (max-width: 768px) {
	.dashboardHowItWorksRow {
		width: 100% !important;
	}
}

.dashboardSampleGameBlueBlackRow {
	/* width: 85%;ak */
	margin: 0 auto 60px auto !important;
}

@media (max-width: 768px) {
	.dashboardSampleGameBlueBlackRow {
		width: 100%;
	}
}

@media (min-width: 768px) {
	.introDiv {
		display: flex;
		/* flex-wrap: wrap; */
		align-items: center;
		justify-content: space-between;
		margin: auto;
		width: 100%;
	}
}
.introTrialHeader {
	margin: auto;
	text-align: left;
	color: #ffffff;
	font-size: 24px;
	margin: 0;
}
.introTrialHeader span {font-size: 14px; text-align: center;}

@media (max-width: 768px) {
	.introTrialHeader {
		text-align: center;
	}
}

.dashboardSampleGameBlackCol {
	background-color: #000000;
	/* height: 100px; */
	display: flex;
	padding: 30px !important;
}

.dashboardSampleGameBlueCol {
	background-color: #0050f0;
	height: 100px;
	display: flex;
	padding: 30px !important;
}

@media (max-width: 768px) {
	.dashboardSampleGameBlackCol {
		height: auto;
		display: block;
	}

	.dashboardSampleGameBlueCol {
		height: auto;
	}
}

.dashboardSampleGameBlueHeader {
	text-align: left;
	color: #ffffff;
	margin: auto 0;
}

@media (max-width: 768px) {
	.dashboardSampleGameBlueHeader {
		font-size: 20px;
		font-weight: 700;
	}
}

.dashboardLegendDescriptionRow {
	margin: 0;
}

.dashboardLegendRow {
	padding-bottom: 30px;
	margin: 0;
}

/* @media (min-width: 768px) AND (max-width: 1440px) { */
@media (min-width: 768px) {
	.dashboardLegendRow {
		display: flex;
	}
}

@media (max-width: 768px) {
	.dashboardLegendRow {
		padding-bottom: 0;
	}
}

.dashboardLegendDiv {
	display: block;
	position: relative;
}

.dashboardLegendsMainKeyDiv {
	float: left;
}

.dashboardTooltipDiv {
	float: right;
}

@media (max-width: 768px) {
	.dashboardLegend {
		padding: 0 !important;
	}
}

.dashboardLegendKeysSubText {
	font-size: 18px;
	text-align: left;
}

/* .dashboardLegendDescription {
	line-height: 20px;
	margin-top: 15px;
	float: left;
} */

.dashboardLegendDescriptionCol {
	margin: auto;
}

.dashboardLegendDescriptionDiv {
	text-align: center;
}

.dashboardLegendDescription {
	line-height: 20px;
	margin: 0;
	font-size: 14px;
	/* float: left; */
}

.dashboardHowCol {
	padding-bottom: 60px !important;
}

.dashboardLegendCol1 {
	padding-left: 0;
	padding-right: 0;
}

.dashboardLegendCol2 {
	display: flex;
}

.dashboardLegendKeysDiv {
	display: flex;
}

.dashboardLegendKeysColor {
	height: 20px;
	width: 20px;
}

.dashboardLegendKeysText {
	margin-top: 0px;
	margin-left: 10px;
}

.dashboardTooltipDiv > * {
	padding-bottom: 5px;
}

.dashboardMainSection {
	padding-top: 60px;
}

.dashboardMainContentDiv {
	margin: auto;
	text-align: left;
	padding: 30px 0 60px 0;
}

@media (min-width: 768px) {
	.dashboardMainContentDiv {
		width: 50%;
	}
}

.dashboardMainContentDiv > h5 {
	margin-bottom: 30px;
}

@media (max-width: 768px) {
	.dashboardMainContentDiv {
		padding: 30px 30px 60px 30px;
	}
}

.dashboardMainButtonDiv {
	text-align: center;
}

/* Score Card */

.cardSection {
	padding: 0 60px 60px 60px;
}

@media (max-width: 900px) {
	.cardSection {
		padding: 0 45px 30px 45px;
	}
}

.cardRow {
	display: flex;
	flex-wrap: wrap;
}

.blueTextBox {
	background-color: #0051f3;
	color: #ffffff;
	padding: 30px 60px;
	font-size: 24px;
	text-align: center;
	margin: 0;
}

@media (max-width: 768px) {
	.blueTextBox {
		font-size: 16px;
	}
}

.cardUpdatedText {
	margin: 0;
	padding-left: 15px;
	text-align: center;
	padding: 30px 0;
}

@media (max-width: 900px) {
	.cardUpdatedText {
		padding-left: 0px;
		text-align: center;
		margin: auto;
		padding: 30px 0;
	}
}

@media (min-width: 900px) {
	.cardCol {
		margin: 0;
		padding: 5px 30px 0 30px;
	}
}

.cardCol {
	margin: 15px 0;
	/* height: 400px; */
}

@media (max-width: 1366px) {
	.cardCol {
		margin: 15px auto;
		/* height: 400px; */
		/* width: 300px !important; */
	}
}

.cardHighlight {
	-webkit-box-shadow: 0px 0px 77px -17px rgba(0, 0, 0, 0.75);
	-moz-box-shadow: 0px 0px 77px -17px rgba(0, 0, 0, 0.75);
	box-shadow: 0px 0px 77px -17px rgba(0, 0, 0, 0.75);
}

.cardColDiv {
	/* -webkit-box-shadow: 0px 0px 77px -17px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 77px -17px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 77px -17px rgba(0, 0, 0, 0.75); */
	max-width: 300px !important;
	margin: auto;
}

.cardSpreadDiv {
	padding: 0 15px;
}

@media (min-width: 900px) {
	.cardSpreadRow {
		/* display: flex; */
	}
}

.cardSpread {
	/* padding: 20px 0 30px 0; */
	padding-top: 15px;
	height: 55px;
	margin: 0;
	/* text-align: center; */
	color: #ffffff;
	z-index: 999;
}

/* Card Spread Color Background */

.cardSpreadGreen {
	background-color: #74ad06;
	padding: 0;
}

.cardSpreadDarkGreen {
	background-color: #04b876;
	padding: 0;
}

.cardSpreadYellow {
	background-color: #d6c605;
	padding: 0;
}

.cardSpreadRed {
	background-color: #c71717;
	padding: 0;
}
/* Card Column Color Highlight */
.cardHighlightGreen {
	border-left: 3px solid #74ad06 !important;
	border-right: 3px solid #74ad06 !important;
}

.cardHighlightDarkGreen {
	border-left: 3px solid #04b876 !important;
	border-right: 3px solid #04b876 !important;
}

.cardSpreadDarkGreen.injured {
	background-color: #c71717;
}
.injured-main + .cardTeamRow .cardHighlightDarkGreen {
	border-left: 3px solid #c71717 !important;
	border-right: 3px solid #c71717 !important;
}

.cardSpreadDarkGreen.injured .cardSpread {
	padding-top: 0;
}
.quad_tag span { font-size: 13px; color: #fff; line-height: 1.1; }
.quad_tag span:not(:last-child) {
	border-right: 1px solid #fff;
}

.cardHighlightYellow {
	border-left: 3px solid #d6c605 !important;
	border-right: 3px solid #d6c605 !important;
}

.cardHighlightRed {
	border-left: 3px solid #c71717 !important;
	border-right: 3px solid #c71717 !important;
}

/* Matchup/Team Color and Shadow */

.teamHighlightGreen {
	-webkit-box-shadow: 0px 0px 77px -17px rgba(0, 0, 0, 0.75);
	-moz-box-shadow: 0px 0px 77px -17px rgba(0, 0, 0, 0.75);
	box-shadow: 0px 0px 77px -17px rgba(0, 0, 0, 0.75);
	border: 3px solid #74ad06 !important;
	background-color: #74ad06;
}

.teamHighlightDarkGreen {
	-webkit-box-shadow: 0px 0px 77px -17px rgba(0, 0, 0, 0.75);
	-moz-box-shadow: 0px 0px 77px -17px rgba(0, 0, 0, 0.75);
	box-shadow: 0px 0px 77px -17px rgba(0, 0, 0, 0.75);
	border: 3px solid #04b876 !important;
	background-color: #04b876;
}

.teamHighlightYellow {
	-webkit-box-shadow: 0px 0px 77px -17px rgba(0, 0, 0, 0.75);
	-moz-box-shadow: 0px 0px 77px -17px rgba(0, 0, 0, 0.75);
	box-shadow: 0px 0px 77px -17px rgba(0, 0, 0, 0.75);
	border: 3px solid #d6c605 !important;
	background-color: #d6c605;
}

.teamHighlightRed {
	-webkit-box-shadow: 0px 0px 77px -17px rgba(0, 0, 0, 0.75);
	-moz-box-shadow: 0px 0px 77px -17px rgba(0, 0, 0, 0.75);
	box-shadow: 0px 0px 77px -17px rgba(0, 0, 0, 0.75);
	border: 3px solid #c71717 !important;
	background-color: #c71717;
}

.cardSpreadText {
	float: left;
	font-size: 18px;
	line-height: 32px;
	margin: 0;
}

.cardSpreadNoBetText {
	font-size: 18px;
	line-height: 32px;
	text-align: left;
	margin: 0;
}

.cardSpreadNumber {
	/* float: right; */
	font-size: 18px;
	margin: 0;
	padding-right: 0px;
	line-height: 32px;
}

.cardSpreadNumberTeam {
	float: right;
	font-size: 18px;
	margin: 0;
	padding-right: 0px;
	line-height: 32px;
}

.cardSpreadNoBetNumber {
	text-align: right;
	font-size: 18px;
	margin: 0;
	padding-right: 0px;
	line-height: 32px;
}

.cardTeamRow {
	display: flex;
	flex-wrap: wrap;
	background-color: #ffffff;
	-webkit-box-shadow: 0px 0px 77px -17px rgba(0, 0, 0, 0.75);
	-moz-box-shadow: 0px 0px 77px -17px rgba(0, 0, 0, 0.75);
	box-shadow: 0px 0px 77px -17px rgba(0, 0, 0, 0.75);
}

.cardTeamCol {
	display: flex;
	padding: 15px 0;
	/* background-color: #ffffff; */
}

.cardAdminTeamCol {
	padding: 15px 0;
	text-align: center;
	width: 100% !important;
	text-align: center !important;
	justify-content: center !important;
	/* background-color: #ffffff; */
}

.cardTeamColLeft {
	border-right: 0.5px solid rgba(112, 112, 112, 0.09);
	/* padding-left: 30px; */
	display: block;
	width: 50%;
}

.cardTeamColRight {
	border-left: 0.5px solid rgba(112, 112, 112, 0.09);
	text-align: center;
	display: block;
	/* padding-right: 30px; */
	width: 50%;
}

.cardTeamDiv {
	/* margin: 0; */
}

.cardTeamDivRight {
}

.cardTeamLogo {
	width: auto;
	height: 60px;
	max-width: 60px;
	max-height: 60px;
}

.cardTeamName {
	margin-top: 15px;
	font-size: 16px;
	line-height: 16px;
}

.cardViewGameCol {
	padding: 0;
}

.cardTeamOriginalSpread {
	font-size: 32px;
}

.cardTeamFantasyDate {
	color: #898989;
	line-height: 14px;
	font-size: 12px;
}

.cardTeamFantasyDateSpan {
	/* margin-top: 15px; */
	display: block;
	padding: 15px 0;
}

.cardViewGameButton {
	display: block;
	padding: 15px 0;
	text-align: center;
	border: 1px solid #080808;
	background-color: #080808;
	color: #ffffff;
	text-decoration: none !important;
	font-size: 18px;
}

.cardViewGameButton:hover {
	background-color: transparent;
	color: #080808;
}

.cardDetailsCol {
	padding: 5px 0 0 0;
}

.cardDate {
	font-size: 15px !important;
}

.cardTypeDiv {
	padding: 15px 0;
	text-align: center;
}

.signupButtonDiv {
	text-align: center;
}

.signupButton {
	background-color: #0050f0 !important;
	color: #ffffff !important;
	border: 1px solid #0050f0 !important;
	margin: 0 !important;
	font-family: 'Avenir Next Cyr';
	font-weight: 500;
	border-radius: 5px !important;
	padding: 12px 0 !important;
	transition-duration: 0.4s !important; 
}

@media (min-width:1200px){
	.signupButton { height: 70px; width: 300px !important; font-size: 28px !important;}
}
@media (max-width:1200px){ 
	.signupButton { height: 60px; padding-inline:1rem!important; max-width: 300px !important; font-size: 20px !important;}
}
.signupButton:hover {
	color: #0050f0 !important;
	background-color: #ffffff !important;
	transition-duration: 0.4s;
}
 
.updatesButton {
	background-color: #0050f0;
	color: #ffffff;
	border: 1px solid #0050f0;
	margin: 0 15px 30px auto !important;
	border-radius: 0;
	font-size: 16px;
	padding: 15px;
	transition-duration: 0.4s;
	width: calc(100% - 60px);
}

.updatesButton:hover {
	color: #0050f0 !important;
	background-color: #ffffff !important;
	transition-duration: 0.4s;
}

@media (max-width: 768px) {
	.updatesButton {
		width: 100%;
		margin: 0 auto 30px auto !important;
		padding: 15px 0;
		white-space: normal !important;
		word-wrap: break-word;
	}
}

.howItWorksButton {
	background-color: transparent !important;
	color: #000000 !important;
	border: 1px solid #000000 !important;
	margin: 0 15px 0 auto !important;
	border-radius: 0 !important;
	font-size: 16px !important;
	padding: 15px 0 !important;
	transition-duration: 0.4s !important;
	width: 200px !important;
}

.howItWorksButton:hover {
	color: #ffffff !important;
	background-color: #000000 !important;
	transition-duration: 0.4s;
}

.cardTypeButton {
	margin: 15px !important;
	border-radius: 0 !important;
	font-size: 16px !important;
	padding: 15px 0 !important;
	transition-duration: 0.4s !important;
	width: 200px !important;
	background-color: transparent !important;
	color: #0050f0 !important;
	border: 1px solid #0050f0;
}

.cardTypeButtonActive {
	background-color: #0050f0 !important;
	color: #ffffff !important;
	border: 1px solid #0050f0;
}

.cardTypeButton:hover {
	background-color: #0050f0 !important;
	color: #ffffff !important;
	transition-duration: 0.4s;
}

.cancelRow {
	padding-left: 60px;
	padding-right: 60px;
}

.cancelCol {
	text-align: left;
	display: flex;
	align-items: baseline;
}

.cancelButton {
	margin: 30px 0 0 0;
	border-radius: 0;
	font-size: 16px;
	padding: 15px 0;
	transition-duration: 0.4s;
	width: 200px;
	display: block !important;
	margin-right: auto !important;
}

.cancelButton:hover {
	/* background-color: #0050f0;
  color: #ffffff;
  transition-duration: 0.4s; */
}

.cancelCheckboxLabel {
	margin: 15px 0 0 10px;
}

.btn-default.active,
.btn-default:active,
.open > .dropdown-toggle.btn-default {
	background-color: #0050f0 !important;
	color: #ffffff !important;
	transition-duration: 0.4s;
	border-radius: 0;
	border: 1px solid #0050f0 !important;
}

.upgradeButtonDiv {
	text-align: center;
}

.upgradeButtonHeader {
	text-align: center;
	margin: 0;
	padding: 30px 0 15px 0;
}

.upgradeButtonText {
	text-align: center;
	font-size: 18px;
	padding-bottom: 30px;
}

body.modal-open {
	width: 100% !important;
	padding-right: 0 !important;
	overflow-y: scroll !important;
}

.upgradeButtonMainDiv {
	padding-top: 60px;
}

.howItWorksText {
	padding-bottom: 60px;
}

@media (min-width: 768px) {
	.howItWorksText {
		width: 50%;
		text-align: center;
		margin: auto;
	}
}

@media (max-width: 768px) {
	.howItWorksText {
		padding-left: 30px;
		padding-right: 30px;
	}
}

/* Scoreboard Section */

.scoreboardSection {
	background-color: #000000;
	color: #ffffff;
	padding: 30px;
	margin-bottom: 60px;
}

@media (max-width: 768px) {
	.scoreboardRow > .scoreboardCol:nth-child(1),
	.scoreboardRow > .scoreboardCol:nth-child(2) {
		margin-bottom: 30px !important;
	}
}

.scoreboardPicksDiv {
	text-decoration: none !important;
	display: block;
	color: inherit !important;
}

/* Uncomment when setting "picks" live again */
/* .scoreboardPicksDiv:hover {
  cursor: pointer !important;
  color: #0050f0 !important;
} */

.scoreboardValue {
	background-color: #ffffff;
	color: #000000;
	padding: 30px;
}
@media (max-width: 768px) {
	.scoreboardValue {
		padding: 10px;
	}
}

.scoreboardDisclaimer {
	font-style: italic;
	margin: 0;
}

/* Home Main Section */

/* Home Cover Section */

.homeCoverBackgroundDiv {
	width: 100%;
	background: linear-gradient(180deg, black 0%, #1b1d3d 100%);
}

.homeCoverDiv {
	color: #ffffff;
	padding-top: 15px !important;
	padding-bottom: 35px !important;
}

.homeCoverTextDiv {
	margin: auto;
	padding: 60px 0 30px 0;
}

@media (min-width: 768px) {
	.homeCoverTextDiv {
		width: 80%;
	}
}

.homeCoverHeader {
	font-size: 56px;
	padding-bottom: 24px; 
	margin-bottom: 0;
}

.homeCoverText {
	font-size: 28px;
	font-weight: 500;
	padding-bottom: 30px;
	font-family: 'Avenir Next Cyr';
}

.homeCoverTextBottom {
	font-size: 24px;
	margin: 1rem 0;
	font-weight: 500;
	padding-top: 30px;
	padding-bottom: 30px;
	font-family: 'Avenir Next Cyr';
	color: #CFD6E7;
}

@media (max-width: 768px) {
	.homeCoverHeader {
		font-size: 42px;
	}

	.homeCoverText {
		font-size: 28px;
	}
	.homeCoverTextBottom {
		margin-top: 0;
		padding-block: 10px;
	}
}

.homeCoverLink {
	text-decoration: none !important;
	font-weight: 500 !important;
	color: #2471dd;
}

/* .homeReviewsCol1 {
  padding-left: 60px !important;
  padding-right: 30px !important;
}

.homeReviewsCol2 {
  padding-left: 30px !important;
  padding-right: 60px !important;
} */

/* Home Reviews Section */

@media (max-width: 768px) {
	.homeReviewsDiv {
		padding-top: 60px;
	}
}

.homeReviewsColDiv {
	background: #171a3e;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	border-radius: 12px;
	border: 2px #1c2085 solid;
	padding-left: 30px;
	padding-right: 30px;
	height: 100%;
}

@media (min-width: 768px) {
	.homeReviewsColDivOne {
		width: 80%;
		margin: auto 60px auto auto;
	}

	.homeReviewsColDivTwo {
		width: 80%;
		margin: auto auto auto 60px;
	}
}

@media (max-width: 768px) {
	.homeReviewsCol2 {
		margin-top: 120px !important;
	}
}

.homeReviewsImg {
	width: 120px;
	height: auto;
	margin-top: -60px;
}

.homeReviewsHeader {
	margin: 0;
	padding-top: 30px;
}

.homeReviewsStarsDiv {
	display: flex;
	justify-content: center;
	padding: 30px 0;
}

.homeReviewsStars {
	width: 20px;
	height: auto;
	margin-left: 5px;
	margin-right: 5px;
}

.homeReviewsName {
	padding-bottom: 60px;
}

.homeReviewsQuoteImg {
	position: absolute;
	width: 50px;
	height: auto;
	bottom: 15px;
	right: 15px;
}

.homeDifferenceDiv {
	padding-top: 60px;
	padding-bottom: 60px;
}

@media (max-width: 768px) {
	.homeDifferenceDiv {
		padding-bottom: 0;
	}
}

.homeDifferenceHeader {
	font-size: 56px;
}

@media (max-width: 768px) {
	.homeDifferenceHeader {
		font-size: 42px;
	}
}

.homeDifferenceText {
	font-size: 28px;
}

/* Home Health Scores Section */

.homeHealthScoresRow {
	padding: 30px 0;
}
@media (min-width:1300px){
	.homeHealthScoresRow {
		padding-block: 60px;
	}
}

.homeHealthScoresTextDiv {
	text-align: left;
}

.homeHealthScoresHeader {
	font-size: 40px;
	margin-bottom: 30px;
	width: fit-content;
}

@media (max-width: 768px) {
	.homeHealthScoresHeader {
		text-align: center !important;
		width: auto;
	}
}

.homeHealthScoresHeaderUnderline {
	height: 4px;
	display: block;
	border-radius: 2px;
}

.homeHealthScoresHeaderUnderlinePlayer {
	background-color: #04b876;
}

.homeHealthScoresHeaderUnderlinePositional {
	background-color: #74ad07;
}

.homeHealthScoresHeaderUnderlineTeam {
	background-color: #d6c606;
}

.homeHealthScoresText {
	font-size: 24px;
}

@media (min-width: 991.99px) {
	.homeHealthScoresImgDiv {
		text-align: right;
	}
}

.homeHealthScoreImg {
	box-shadow: 4px 6px 20px rgba(0, 0, 0, 0.12);
}

.homePlayerHealthScoreImg {
	width: 300px;
	height: auto;
}

.homePositionalHealthScoreImg {
	width: 500px;
	height: auto;
}

.homeTeamHealthScoreImg {
	width: 300px;
	height: auto;
}

@media (max-width: 768px) {
	.homeHealthScoreImg {
		width: 100%;
		height: auto;
		margin-top: 30px;
	}
}

/* Home Win More. Brag More. Section */

.homeWinMoreDiv {
	background-color: #000000;
	color: #ffffff;
	padding-top: 60px;
	padding-bottom: 60px;
}

.homeWinMoreHeader {
	font-size: 56px;
	padding-bottom: 30px;
}

.homeWinMoreText {
	font-size: 28px;
}

@media (max-width: 768px) {
	.homeWinMoreHeader {
		font-size: 42px;
	}
}

/* Home Science Section */

.homeScienceDiv {
	padding-top: 60px;
	padding-bottom: 60px;
}

.homeScienceHeader {
	font-size: 56px;
}

@media (max-width: 768px) {
	.homeScienceHeader {
		font-size: 42px;
	}
	.homeScienceDiv { 
		padding-bottom: 0;
	}
}

.homeScienceText {
	font-size: 28px;
}

.homeScienceCardDiv {
	padding-top: 60px;
}

@media (max-width: 768px) {
	.homeScienceCard {
		margin-bottom: 30px;
	}
}

.homeScienceCard {
	text-align: left;
	background-color: #f5f5f5;
	border-radius: 4px;
	font-size: 18px;
}

/* .homeScienceCardImg {
  width: 100%;
  height: 150px;
} */

.howScienceCardImgDiv {
	background-size: cover !important;
	background-repeat: no-repeat !important;
	width: 100%;
	height: 150px;
}

.howScienceCardImgDivFantasy {
	background: url('../../global/img/carousel/fantasyscores.jpg');
}

.howScienceCardImgDivPower {
	background: url('../../global/img/carousel/powerofinsideinjuries.jpg');
}

.howScienceCardImgDivImpact {
	background: url('../../global/img/carousel/impactofinjuries.jpg');
}

.howScienceCardImgDivDoctorsEdge {
	background: url('../../global/img/carousel/doctorsedge.png');
}

.homeScienceCardTextDiv {
	padding: 20px;
}

.homeScienceCardText {
	line-height: 20px;
}

.homeScienceCardLink {
	text-decoration: none !important;
	color: #2471dd;
}

/* Home Footer Section */

.homeFooterDiv {
	padding-top: 36px;
	padding-bottom: 60px;
}

.homeFooterHeader {
	font-size: 40px;
	padding-bottom: 30px;
	line-height: 1.4;
}

.matchupCardsHeader {
	font-size: 48px;
} 

@media (max-width: 768px) {
	.homeNbaHeadshotRow {
		padding-left: 0px !important;
		padding-right: 0px !important;
	} 
}

.homeNbaHeadshotImg {
	width: 100%;
	height: auto;
}

.homeNbaHeadshotHealthScoreDiv {
	padding: 15px;
	z-index: 11;
}

.homeNbaHeadshotHealthScoreDiv1 {
	background-color: #04b876;
}

.homeNbaHeadshotHealthScoreDiv2 {
	background-color: #74ad07;
}

.homeNbaHeadshotHealthScoreDiv3 {
	background-color: #d6c606;
}

.homeNbaHeadshotHealthScoreDiv4 {
	background-color: #c71718;
}

.homeNbaHeadshotHealthScore {
	color: #ffffff;
}
.homeNbaHeadshotCol {overflow: hidden; background-color: #1B1D3D; border: 1px solid #353A7A;}

@media (min-width:1200px){
	.homeNbaHeadshotRow .col-lg-3 {
		padding: 0 20px;
	}	
}
@media (max-width:1200px){ 
	.homeNbaHeadshotCol {margin-bottom: 20px;}
}

.player_overlay {
	position: absolute;
	top: 100%;
	height: 100%;
	padding: 16px;
	background: rgba(17, 29, 61, .80);
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	gap: 16px;
	width: 100%;
	transition: .5s ease;
	visibility: hidden;
}
.homeNbaHeadshotCol:hover .player_overlay {
	top: 0; visibility: visible;
}
.title_group {text-align: left;} 
.title_group span {color: #7080E4; font-size: .875rem; font-family: 'Avenir Next Cyr'; font-weight: 400;}
.title_group .h6 { font-weight: 700; color: #fff; margin-bottom: 0; font-size: 1.5rem; white-space: nowrap; text-overflow: ellipsis; overflow: hidden;}
.injury_info {text-align: left; font-weight: 500;}
.injury_info span.injury_title {font-weight: 500;}
.injury_info span { display: block; color: #fff; font-size: .725rem; font-weight: 400;}

/* Landing Page Section */

.landingPageCoverDiv {
	width: 100%;
	background: linear-gradient(180deg, black 0%, #1b1d3d 100%);
	color: #ffffff;
	overflow: hidden;
	margin-bottom: 60px;
}

@media (min-width: 768px) {
	.landingPageCoverDiv {
		height: 80vh;
	}
}

@media (max-width: 768px) {
	.landingPageCoverDiv {
		padding: 30px;
	}
}

@media (min-width: 768px) {
	.landingPageCoverImgDiv {
		background: url('./img/nbaLandingPageCover.png');
		background-size: contain;
		background-repeat: no-repeat;
		background-position-x: center;
		height: 100%;
		width: 100%;
	}
}

@media (min-width: 768px) {
	.landingPageCoverHeaderDiv {
		padding: 45px 0 0 0;
	}
}

.landingPageCoverHeader {
	font-size: 48px;
	margin-bottom: 30px;
}

@media (max-width: 768px) {
	.landingPageCoverHeader {
		font-size: 42px;
	}
}

.landingPageCoverText {
	font-size: 24px;
	margin-bottom: 0;
}

.landingPageCoverImg {
	width: 100%;
	height: auto;
	margin: 30px auto;
}

@media (min-width: 768px) {
	.landingPageCoverImg {
		display: none;
	}
}

.landingPageCoverSignupDiv {
	font-size: 26px;
}

@media (min-width: 768px) {
	.landingPageCoverSignupDiv {
		text-align: left;
		position: absolute;
		left: 22.5%;
		width: 300px;
		bottom: 35%;
	}
}

@media (max-width: 768px) {
	.landingPageCoverSignupDiv {
		text-align: center;
		/* position: relative; */
		font-size: 26px;
		/* width: 300px; */
		/* bottom: 35%; */
	}
}

.landingPageCoverSignupHeader {
	margin-bottom: 30px;
	font-size: 26px;
}

@media (max-width: 768px) {
	.landingPageCoverSignupHeader {
		font-size: 32px;
	}
}

.landingPageCoverInsightDiv {
	display: flex;
	align-items: center;
	left: calc(100vw - 80%);
	top: 15%;
	display: none;
}

.landingPageCoverInsightPart {
	margin: 0 15px 0 0;
	background-color: #353a7a;
	border-radius: 30px;
	padding: 10px;
	font-size: 14px;
}

.landingPageCoverInsightSmallCircle {
	height: 10px;
	width: 10px;
	background-color: #ffffff;
	border-radius: 50%;
}

.landingPageCoverInsightLine {
	height: 1px;
	width: 100px;
	background-color: #ffffff;
	margin-right: 15px;
}

.landingPageCoverInsightBigCircle {
	height: 30px;
	width: 30px;
	background-color: #ffffff;
	border-radius: 50%;
}
