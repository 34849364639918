/* Main Section */

.playerMainSection {
	background-color: #f7f7f7;
	min-height: 100vh;
}

.playerMainDiv {
	width: 85%;
	background-color: #ffffff;
	margin: auto;
	min-height: 100vh;
	/* padding-bottom: 60px; */
}

@media (max-width: 768px) {
	.playerMainDiv {
		width: 100%;
		overflow-x: hidden;
	}
}

.playerBoxShadow {
	/* box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.75); */
	padding: 0 15px;
}

/* Player Top Row Info */

.playerInfoRow {
	padding: 75px 60px 60px 60px;
}

.playerInfoCol {
	padding-top: 30px;
}

@media (max-width: 768px) {
	.playerInfoRow {
		padding: 75px 30px 30px 30px;
	}
	.playerInfoCol {
		text-align: center;
	}
}

.playerInfoMobileTeamLogoDiv {
	text-align: center;
	padding-top: 30px;
}

.playerInfoMobileTeamLogo {
	width: 150px;
	height: auto;
}

@media (min-width: 768px) {
	.playerInfoMobileTeamLogoDiv {
		display: none;
	}
}

.playerInfoTopDiv > * {
	font-size: 28px;
}

@media (min-width: 768px) {
	.playerInfoTopDiv {
		text-align: left;
	}

	.playerInfoTopDiv > * {
		display: inline;
		font-size: 42px;
	}
}

.playerInfoTopDiv p {
	margin: 0;
}

.playerInfoImg {
	width: 100%;
	max-width: 175px;
	height: auto;
}

@media (max-width: 768px) {
	.playerInfoImg {
		width: 100px;
		height: auto;
		margin-top: 30px;
	}
}

@media (min-width: 768px) {
	.playerInfoName {
		padding-left: 60px;
	}
}
 

@media (min-width: 768px) {
	.playerInfoPosition {
		/* float: right; */
		padding-left: 60px;
	}
}
 
@media (min-width: 768px) {
	.playerInfoStatsMobileDiv {
		display: none;
	}
}

@media (max-width: 768px) {
	.playerInfoStatsDesktopDiv {
		display: none !important;
	}
}

@media (max-width: 1366px) {
	/* .playerInfoStatsDiv {
    overflow-x: auto !important;
  } */

	.playerInfoStats {
		padding-right: 30px;
	}
}

.playerInfoStatsTitle,
.playerInfoStatsValue {
	display: block;
}

.playerInfoStatsValue,
.playerTotalInjuryValue,
.playerHistoryValue {
	font-weight: bold !important;
}

.playerInfoTeamLogoCol {
	text-align: right;
}

@media (max-width: 768px) {
	.playerInfoTeamLogoCol {
		display: none;
	}
}

.playerInfoTeamLogo {
	width: 100%;
	max-width: 175px;
	height: auto;
}

/* Player Progress Bar */

.high {
	color: #c71717;
}

.elevated {
	color: #d6c605;
}

.low {
	color: #75af07;
}

.tooltipQuestion {
	margin: 0;
}

.playerInfoProgressDiv {
	padding: 15px 0 15px 0;
}

.playerInfoProgressDiv p {
	margin: 0;
}

.playerInfoProgressValue {
	padding-left: 30px;
}

.rc-progress-line {
	width: 100% !important;
	margin-top: 11px;
}

.playerInfoProgressBarValue {
	padding-inline: 15px; 
}

/* Player Total Injury */

.playerTotalInjuryRow {
	border-top: 1px solid rgba(112, 112, 112, 0.16);
	border-bottom: 1px solid rgba(112, 112, 112, 0.16);
	padding: 30px 60px !important;
	margin: 0;
	justify-content: center;
}

@media (min-width: 768px) {
	.playerTotalInjuryDiv {
		display: table;
	}
	.playerTotalInjury {
		display: table-cell;
		vertical-align: middle;
	}
	.playerTotalInjuryValue {
		font-size: 30px;
		display: table-cell;
		vertical-align: middle;
		margin: 0;
		padding-left: 45px;
	}
}

@media (max-width: 768px) {
	.playerTotalInjuryDiv {
		text-align: center;
	}
}

.playerTotalInjury {
	line-height: 30px !important;
	margin: 0;
}

.playerTotalInjuryValue {
	font-size: 30px;
	margin: 0;
}

/* Player History */

.playerHistoryDiv {
	padding: 30px 60px;
}

.playerTotalInjuryHistory {
	font-size: 18px;
	margin: 0;
	padding: 0 0 30px 0;
}

.playerHistoryDiv p {
	margin: 0;
	line-height: 16px;
}

.playerHistoryRow {
	margin: 15px 0;
	justify-content: center;
}

/* @media (min-width: 1367px) {
  .playerHistoryRow {
    width: 75%;
  }
} */

.playerHistoryLabel {
	color: #b1b1b1;
}

@media (max-width: 768px) {
	.playerHistoryLabel {
		padding-bottom: 0px;
	}
	.playerHistoryValue {
		padding-bottom: 10px;
	}
}


/* Updated css */
.playerInfoRow {
	padding: 20px 15px;
	max-width: 800px;
	margin: 80px auto 0;
}
.by_week { font-size: 12px;}
.profile_hdr .h3 {
	font-size: 20px;
	color: #000;
}
.profile_hdr { margin-bottom: 20px;}
.playerInfoStatsDiv {
	gap: 10px;
	flex-wrap: wrap;
	text-align: center;
}
.playerInfoStatsDiv span {
	font-size: 14px; color: #000; text-transform: uppercase; 
}
.player_name .badge {
	font-size: 16px;
	padding:7px 20px;
	text-align: center;
}
.playerInfoDiv {
	padding: 20px 0;
	width: 100%;
	border-width: 1px 0;
	border-style: solid;
	border-color: #ddd;
	margin-bottom: 20px;
}
.current_status li {
	margin-bottom: 15px;
}
.current_status li:last-child {
	margin-bottom: 0px;
}
.info_icon { border: 1px solid #ddd; padding: 4px; text-align: center; border-radius: 50%; min-width: 35px; min-height: 35px;}
.progressbar_elem svg {
	width: 100%; height: 20px;
}
.progress_value {
    max-width: 200px;
    min-width: 150px;
    text-align: center;
    justify-content: center;
}
@media (max-width:767px){
	.progress_value { margin-top:8px;}
}
