:root {
	--black-color: #000000;
}
.profile-view { max-width: 600px; margin: 0 auto; }
.title_h2 h2 {color: var(--black-color);}
.title_h2 p {font-size:1rem; color: #333;}

.profile_info { background-color: #1b1d3d; border-radius: 7px; padding: 30px; }
.profile_info label {font-weight: 600; color: rgba(255,255,255,.7); font-size: 14px; margin-bottom: 4px;}
.profile_info p {color: var(--bs-white); font-size: 1rem; margin-bottom: 10px; font-weight: 600;}
.profile_info .h6 { color: var(--bs-white); font-size: 20px; font-weight: 400; padding-bottom: 15px; margin-bottom: 25px; border-bottom: 1px solid rgba(255,255,255,.5); }

.edit-button { position: absolute; right: 0; top: -10px; border: 0; padding: 5px 10px; font-size: 14px; border: 1px solid var(--bs-btn-bg); background-color: var(--bs-btn-bg); color: var(--bs-white); border-radius: 4px; z-index: 1;}

.profile-form {
	background-color: #1b1d3d;
	border-radius: 7px; 
    padding: 30px; 
}
.profile-form label{ color: var(--bs-white);}
