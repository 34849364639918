/* User Header */

.userHeaderSection {
	border-bottom: 1px solid rgba(112, 112, 112, 0.16);
}

.userHeaderMatchSection {
	padding: 30px 15px 0 15px;
	position: fixed;
	background-color: rgb(247, 247, 247);
	width: 85%;
	top: 60px;
	z-index: 999;
	height: 75px;
}

@media (max-width: 768px) {
	.userHeaderMatchSection {
		width: 100%;
		height: 100px;
	}
}

.userHeaderSectionPadding {
	padding: 10px 30px;
}

@media (min-width: 900px) {
	.userHeaderSectionPadding {
		padding: 0 95px;
	}
}

.userHeaderTextRow {
	padding-bottom: 15px;
}

.userHeaderNameCol {
	padding: 0 !important;
}

@media (max-width: 768px) {
	.userHeaderNameCol {
		padding: 0 30px;
	}
}

.userHeaderName {
	text-align: left;
	font-size: 24px;
	margin: 0;
	font-weight: bolder;
	margin-top: 20px;
}

.userHeaderTitle {
	text-align: center;
	margin: 15px 0 0 0;
	font-size: 24px;
	font-weight: 700;
}

@media (max-width: 900px) {
	.userHeaderName {
		text-align: center;
	}
	.userHeaderTitle {
		font-size: 20px;
		line-height: 25px;
	}
}

.userHeaderDate {
	text-align: right;
	margin: 0;
}

.userHeaderDateDashboard {
	text-align: right;
	margin: 20px auto 0 auto;
	font-size: 18px;
}

.userHeaderCloseMobile {
	font-size: 32px;
	text-align: right;
	/* display: inline-block; */
	text-decoration: none;
	/* color: #0051f3; */
	color: #000000;
}

.userHeaderCloseIcon {
	padding-left: 15px;
}

.userHeaderClose {
	font-size: 32px;
	/* display: inline-block; */
	text-decoration: none;
	color: #000000;
	text-align: center;
	line-height: 20px;
}

.userHeaderClose > * {
	display: inline;
}

.userHeaderClose:hover {
	text-decoration: none;
	color: #0051f3;
}

@media (min-width: 768px) {
	.userHeaderCloseTable {
		display: table;
	}

	.userHeaderCloseIcon,
	.userHeaderCloseText {
		display: table-cell;
		vertical-align: middle;
		margin: 0;
	}
}

.userHeaderCloseText {
	font-size: 16px;
	padding-right: 15px;
}

/* Legend Description */

@media (min-width: 768px) {
	.userHeaderDescription {
		float: left;
	}
}
.userHeaderDescription {
	margin: 0;
}

@media (min-width: 768px) {
	.userHeaderDate {
		float: left;
	}
}

.userHeaderNavDiv {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
}

.userHeaderNavLink {
	text-decoration: none !important;
}

.userHeaderNavItem {
	margin: 0 10px !important;
	font-size: 18px !important;
	color: #0051f3 !important;
	font-family: 'Avenir Next' !important;
}

.userHeaderNavArrow {
	font-weight: 900;
	font-size: 18px;
	padding-bottom: 2.5px;
}

.userHeadCountdown {
	text-align: right;
	margin: 0 0 0 auto;
	font-weight: 900;
	padding-right: 10px;
}

@media (max-width: 768px) {
	.userHeadCountdown {
		text-align: right;
		margin: 0 0 0 10px;
	}
}
