.confirmationSection {
	padding: 60px;
}

.confirmationButton {
	padding: 15px 30px;
	color: #ffffff;
	background-color: #0051f3;
	border: 1px solid #0051f3;
	text-decoration: none !important;
	border-radius: 5px;
	transition-duration: 0.4s;
	margin: 30px auto 0 auto;
	display: block;
	width: fit-content;
}

.confirmationButton:hover {
	color: #0051f3;
	background-color: #ffffff;
	transition-duration: 0.4s;
}
