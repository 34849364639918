.adminDiv {
	padding: 60px;
	text-align: -webkit-center;
}

.adminDashboardRow {
	/* width: 80%; */
	margin: auto;
}

.adminDashboardButtonRow {
	width: 80%;
	margin: auto;
}

.adminDashboardTableRow {
	width: 80%;
	overflow-x: auto;
	border: 1px solid #c9c9c9;
	margin: 60px auto !important;
}

.adminDashboardCol {
	padding: 15px 5px;
	height: 130px;
}

.adminDashboardTableCol {
	padding: 15px 5px;
}

.adminButtonActive,
.adminButtonActive:hover {
	background-color: #ebebeb !important;
	border: 1px solid #ebebeb !important;
	color: #0050f0 !important;
}

.adminButton {
	display: block;
	width: 100%;
	margin: 0;
	background-color: #0050f0;
	border: 1px solid #0050f0;
	color: #ffffff;
	border-radius: 0;
	padding: 15px 0;
	font-size: 18px;
	transition-duration: 0.4s;
}

.adminButton:hover {
	/* background-color: #ebebeb; */
	background-color: initial !important;
	color: initial !important;
}

.adminCalculationsButton1 {
	margin-right: 0 !important;
	/* margin-left: auto !important; */
	display: block !important;
}

.adminCalculationsButton2 {
	margin-left: 0 !important;
	/* margin-left: auto !important; */
	display: block !important;
}

.adminDashboardTable {
	padding: 200px;
}

.react-bootstrap-table table {
	table-layout: auto !important;
}

.react-datepicker-wrapper {
	width: 100%;
}

.statusLabel {
	color: #ffffff;
	width: 80%;
	margin: auto;
	font-size: 24px;
	position: fixed;
	z-index: 999;
	left: 0;
	right: 0;
	opacity: 90%;
	padding: 15px !important;
}

.successLabel {
	background-color: #4bb543;
}

.errorLabel {
	background-color: red;
}

.hideDiv {
	animation: FadeAnimation 3s ease-in 0.2s forwards;
}

@keyframes FadeAnimation {
	0% {
		opacity: 1;
		visibility: visible;
	}

	100% {
		opacity: 0;
		visibility: hidden;
	}
}

.adminDashboardTablesRow {
	border: 1px solid #ebebeb;
	width: 80%;
	margin: auto;
}

.calculationsRow {
	padding: 60px 0 15px 0;
}

.showTablesCol {
	padding: 20px 15px !important;
}

.rdrCalendarWrapper,
.rdrDefinedRangesWrapper {
	z-index: 9999 !important;
}

/* Admin Override Section */

.authorizationAlert {
	text-align: center;
	font-size: 32px;
}

.adminHeaderDiv {
	text-align: center;
	padding-bottom: 30px;
}

.adminTable {
	margin-top: 30px;
}

/* Matchup Modal Section */

@media (min-width: 768px) {
	#adminMatchupModal {
		max-width: 50% !important;
	}
}

#adminMatchupModal > .modal-content .modal-header {
	font-size: 20px;
}

.adminMatchupModal {
	background-color: #ffffff;
	border: 1px solid #ffffff;
	border-radius: 15px;
	/* min-height: 100%; */
	text-align: center;
	padding: 30px;
}

#adminMatchupModal > .modal-content {
	/* background: none;
  border: none; */
}

#adminMatchupModal > .modal-content .modal-header {
	/* display: none; */
}

.adminPickValues {
	color: green;
	font-weight: 900 !important;
}

.modifyMatchupButton {
	width: 100px !important;
}
