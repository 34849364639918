#menu {
	background-color: #000000 !important;
}

@media (max-width: 768px) {
	#menu {
		padding-top: 15px;
		padding-bottom: 15px;
	}
}

.navbarContainer {
	display: flex;
	flex-wrap: inherit;
	align-items: center;
	justify-content: space-between;
}

@media (min-width: 768px) {
	.navbarContainer {
		width: 85%;
		padding-left: 15px;
		padding-right: 15px;
		margin: auto;
	}
}

@media (max-width: 768px) {
	.navbarContainer {
		width: 100%;
		padding-left: 15px;
		padding-right: 15px;
	}
}

#menu .logoLink {
	margin: auto;
	/* transform: translate(75%, 0%); */
	transform: translate(-175%, 0%);
}

@media (max-width: 768px) {
	#menu .logoLink {
		display: none;
	}
}
 
@media (max-width: 768px) {
	#menu {
		background: #000000 !important;
	} 
}

#menu .logo {
	width: 150px;
	height: auto;
}
 
@media (min-width: 768px) {
	.navItemStyleSpan {
		border: 2px solid #ffffff;
		padding: 5px 30px;
		transition-duration: 0.4s;
	}

	.navItemStyleSpan:hover {
		background-color: #ffffff;
		color: #0050f0;
		transition-duration: 0.4s;
	}
}

.modalCloseBtnLink {
	display: block;
	text-align: right;
	padding-bottom: 15px;
	width: 25%;
	margin-right: 0;
	margin-left: auto;
}

.modalCloseBtn {
	margin-right: 0;
	margin-left: auto;
	width: 20px;
	height: auto;
}

.profileIconDiv {
	border: 1px solid #ffffff;
	border-radius: 50%;
	padding: 15px;
}

.profileIcon {
	width: 15px;
	height: 15px;
}

/* Login Section */

@media (min-width: 768px) {
	#loginModal {
		max-width: 50% !important;
	}
}

.loginDiv,
.forgotPwdDiv {
	background-color: #ffffff;
	border: 1px solid #ffffff;
	border-radius: 15px;
	/* min-height: 100%; */
	text-align: center;
	padding: 30px;
}

#loginModal > .modal-content {
	background: none;
	border: none;
}

#loginModal > .modal-content .modal-header {
	display: none;
}

.loginModalHeader {
	color: #ffffff;
}

.thirdPartySignInBtn {
	background-color: #f8f9fc;
	border: 1px solid #000000;
	color: #000000;
	padding: 15px;
	width: 100%;
	margin-bottom: 15px;
	border-radius: 7px;
}

.hrDiv {
	width: 100%;
	height: 20px;
	border-bottom: 3px solid grey;
	text-align: center;
}

.hrDiv > span {
	background-color: #ffffff;
	padding: 0 10px;
	margin-top: 7px;
	display: inline-block;
}

.hrStyle {
	width: 85%;
	border: 0;
	border-top: 1.5px solid #ccc;
	padding: 15px 0;
	margin: auto;
}

.loginForm {
	/* padding-top: 30px; */
}

.loginInput {
	margin: auto;
	display: block;
	width: 100%;
	margin-bottom: 15px;
	padding: 15px;
	border-radius: 7px;
}

/* Mobile Toggle Menu */

.toggleMenu {
	border: none !important;
	box-shadow: none !important;
}
 
@media (max-width: 768px) {
	#menu .navbar-collapse {
		margin-top: 30px;
	}

	#menu .navbar-nav {
		text-align: center;
	}

	#menu .navbar-nav a, .header_dropMenu button {
		border-top: 2px solid var(--bs-white);
		width: 100%;
		font-size: 18px;
		padding: 15px 10px;
	}
}

#menu .facebookIcon {
	width: 12.5px;
	height: auto;
	margin-top: -5px;
}

#menu .twitterIcon {
	width: 20px;
	height: auto;
}

/* Subscription Section */

.subscriptionLogo {
	width: 60px;
	height: auto;
}

/* Banner Alert Section */

.bannerSubscriptionAlert {
	background-color: #0050f0 !important;
	color: #ffffff !important;
	text-align: center;
	margin-bottom: 0 !important;
}

.bannerSubscriptionAlertText {
	font-size: 24px;
}

.bannerSubscribeButton {
	padding: 10px 30px;
	color: #0050f0;
	background-color: #ffffff;
	border: 1px solid #ffffff;
	text-decoration: none !important;
	border-radius: 5px;
	transition-duration: 0.4s;
	margin: 15px auto 0 auto;
	display: block;
	width: fit-content;
}

.bannerSubscribeButton:hover {
	color: #ffffff;
	background-color: transparent;
	transition-duration: 0.4s;
}

/* Main Signup and Login Section */

.signupMainSection {
	min-height: 100vh;
	width: 100%; 
	background: linear-gradient(180deg, black 0%, #1b1d3d 100%);
	padding: 60px;
}

@media (max-width: 768px) {
	.signupMainSection {
		padding: 30px;
	}
}

.signupLogo {
	width: 220px;
	height: auto;
}

@media (max-width: 768px) {
	.signupLogo {
		width: 250px;
	}
}

.signupHeaderText {
	margin-top: 24px;
	color: #ffffff;
	font-size: 20px;
	font-weight: 400;font-family: 'Avenir Next Cyr';
}

.signupFormDiv form {
	background-color: #1b1d3d;
	padding: 30px;
	margin: 0 auto 20px;
	border-radius: 7px;
	box-shadow: 0 0 10px rgba(0,0,0,.5);
}

.modal-body {
	padding: 8px!important;
}

@media (min-width: 768px) {
	.signupFormDiv {
		width: 600px;
		margin: 0 auto;
	}
	.modal .signupMainSection {
		min-height: initial;
		border-radius: 6px;
	}
	.modal .signupFormDiv {
		width: 100%;
	}	
}


.loginFlowText {
	font-size: 18px !important;
	text-align: center;
	color: #ffffff;
	font-weight: 500;
	margin-top: 15px;
}

.loginFlowText a {
	margin-left: 10px;font-weight: 500;
}

.loginFlowLink {
	display: initial; 
	font-size: 20px !important;
	color: #8ca1ed !important;
	transition-duration: 0.4s;
	font-weight: 500;
}

.loginFlowLink:hover {
	color: #ffffff !important;
	transition-duration: 0.4s;
}

.loginButton {
	width: 100% !important;
	background-color: #0050f0 !important;
	border: 1px solid #0050f0 !important;
	color: #ffffff !important;
	transition-duration: 0.4s !important;
	padding: 10px !important;
	border-radius: 7px !important;
	margin-top: 25px;
	min-height: 48px;
	font-weight: 400;
	font-size: 1.125rem;
	text-transform: uppercase;
}
@media (min-width:1440px){
	.loginButton {
		min-height: 58px;
	}	
}

.loginButton:hover {
	background-color: transparent !important;
	transition-duration: 0.4s !important;
}

.loginButton:disabled {
	cursor: not-allowed !important;
}

.signupHomeLink {
	font-size: 16px !important;
	text-decoration: none !important;
	color: #8ca1ed;
	display: flex;
	align-items: center;
	justify-content: center;
	transition-duration: 0.4s !important;
	margin-top: 15px;
}

.signupHomeLink:hover {
	color: #ffffff !important;
	transition-duration: 0.4s !important;
}

.signupHomeLinkArrow {
	width: 15px;
	height: auto;
	margin-right: 5px;
}

.signupSubscribeDiv {
	text-align: left;
}

.signupSubscribeText {
	font-size: 24px;
	color: #7080e4;
}

.signupSubscribeHeader {
	font-size: 40px;
	color: #ffffff;
	font-weight: 600;
}

.signupSubscribeHeaderPrice {
	font-size: 40px;
	color: #ffffff;
	font-weight: 700;
}

.signupSubscribeHeaderPriceSub {
	font-size: 16px;
	font-weight: 400;
	color: #ccdaf9;
}

.signupSubscribeUL {
	color: #ffffff;
	padding: 0;
}

.signupSubscribeUL .signupSubscribeLI {
	background: url('./img/checkmark.png') no-repeat left center;
	background-size: 15px;
	padding: 5px 10px 5px 30px;
	list-style: none;
	margin: 0;
	vertical-align: middle;
}
