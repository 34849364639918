.generalTooltipIcon {
	border: 1px solid grey;
	border-radius: 50%;
	width: 30px;
	height: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.generalTooltipIcon:hover {
	cursor: pointer;
}

.customTooltip {
	background-color: #ffffff !important;
	border: 1px solid #cccccc !important;
	color: #000000 !important;
	padding: 0 !important;
	z-index: 999 !important;
	opacity: 1 !important;
}

.customTooltip .tooltip-inner {
	background-color: #ffffff;
	color: #000000;
}

.tooltipTitle {
	border-bottom: 1px solid #cccccc;
	padding: 5px;
}

.tooltipDescription {
	padding: 5px;
	margin-top: -20px;
}

/* Player Legend */

.playerPercentDiv {
	padding: 10px 0;
}

.playerPercentInfoDiv {
	padding: 5px;
}

.playerPercentInfoDiv p {
	margin: 0;
}

.playerPercentInfoSquare {
	width: 20px !important;
	height: 20px !important;
	margin-right: 30px;
}

.playerPercentInfoLabel {
	padding: 0 10px;
	font-weight: 700;
}

.playerPercentInfoSquarePoor {
	background-color: #c71717;
}

.playerPercentInfoSquareElevated {
	background-color: #d6c605;
}

.playerPercentInfoSquareHigh {
	background-color: #75af07;
}
