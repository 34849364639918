.footerSection {
	background-color: #000000;
	color: #ffffff;
	padding: 30px 60px !important;
}

/* @media (max-width: 1200px) {
  .footerSection {
    display: none;
  }
} */

/* Logos */

.footerRow {
	align-items: center;
}

@media (min-width: 768px) {
	.footerLogoCol {
		text-align: left !important;
	}
}

.footerLogoLink {
	display: block;
}

.footerLogo {
	width: 200px;
	height: auto;
	margin-bottom: 15px;
}

.sportsDataLogo {
	width: 200px;
	height: auto;
}

.footerLinksDiv {
	text-align: right;
}

@media (max-width: 768px) {
	.footerLinksDiv {
		text-align: center;
		margin-top: 30px;
		margin-bottom: 15px;
	}
}

.footerLink {
	color: #ffffff !important;
	text-decoration: none !important;
	margin-left: 15px;
	margin-right: 15px;
	transition-duration: 0.4s;
}

.footerLink:hover {
	color: #0050f0 !important;
	transition-duration: 0.4s;
}

.footerAddress {
	text-align: center;
	padding-top: 15px;
	margin-bottom: 0;
}
