/* Global Fonts */

@font-face {
	font-family: 'Staatliches';
	src: url(../font/Staatliches-Regular.ttf);
}

@font-face {
	font-family: 'Bebas Neue';
	src: url(../font/BebasNeue-Regular.ttf);
}

@font-face {
	font-family: 'Roboto';
	src: url(../font/Roboto-Regular.ttf);
}

@font-face {
	font-family: 'Avenir Next';
	src: url(../font/Avenir-Next-LT-Pro-Bold-Condensed-Italic_5180.ttf);
}

@font-face {
	font-family: 'Avenir Next';
	src: url(../font/Avenir-Next-LT-Pro-Bold_5182.ttf);
}

@font-face {
	font-family: 'Avenir Next';
	src: url(../font/Avenir_Next_Condensed.ttc);
}

@font-face {
    font-family: 'Avenir Next Cyr';
    src: url('../../font/AvenirNextCyr-Medium.woff2') format('woff2'),
        url('../../font/AvenirNextCyr-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir Next Cyr';
    src: url('../../font/AvenirNextCyr-Regular.woff2') format('woff2'),
        url('../../font/AvenirNextCyr-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir Next Cyr';
    src: url('../../font/AvenirNextCyr-Light.woff2') format('woff2'),
        url('../../font/AvenirNextCyr-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

/* Main */

html {
	scroll-behavior: smooth;
	height: 100%;
}

body {
	zoom: 1;
	/* overflow-x: hidden; */
	height: 100%;
	font-family: 'Avenir Next', sans-serif;
}

@media (max-width: 768px) {
	body {
		zoom: 85%;
	}
}

.modal-backdrop.fade {
	width: 100% !important;
	height: 100% !important;
}

.overflowXHidden {
	overflow-x: hidden !important;
}

.absolute {
	position: absolute;
}

.flex {
	display: flex;
}

@media (max-width: 768px) {
	.desktopBreakPoint {
		display: none;
	}
}

.displayTable {
	display: table;
}

.displayTableCell {
	display: table-cell;
	vertical-align: middle;
}

.displayNone {
	display: none;
	transition:
		visibility 0s,
		opacity 0.5s linear;
}

.displayBlock {
	display: block;
}

.hideBodyScroll {
	height: 100vh !important;
	overflow-y: hidden !important;
}

.blur {
	text-shadow: 0 0 10px #000000;
	color: transparent;
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Safari */
	-khtml-user-select: none; /* Konqueror HTML */
	-moz-user-select: none; /* Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome and Opera */
}

.hoverDelay {
	transition-duration: 0.4s !important;
}

.brandColor {
	color: #0050f0;
}

.bold {
	font-weight: 700 !important;
}

.heavyBold {
	font-weight: 900;
}

.mainFontRegular {
	font-family: 'Staatliches', sans-serif;
	font-weight: 400 !important;
}

.mainFontItalic {
	font-family: 'Staatliches', sans-serif;
	font-weight: 400 !important;
	font-style: italic !important;
}

.mainFontMedium {
	font-family: 'Staatliches', sans-serif;
	font-weight: 600 !important;
}

.mainFontBold {
	font-family: 'Staatliches', sans-serif;
	font-weight: 700 !important;
}

.secondaryFontRegular {
	font-family: 'Bebas Neue', sans-serif;
	font-weight: 400 !important;
}

.secondaryFontItalic {
	font-family: 'Bebas Neue', sans-serif;
	font-weight: 400 !important;
	font-style: italic !important;
}

.secondaryFontMedium {
	font-family: 'Bebas Neue', sans-serif;
	font-weight: 600 !important;
}

.secondaryFontBold {
	font-family: 'Bebas Neue', sans-serif;
	font-weight: 700 !important;
}

.tertiaryFontRegular {
	font-family: 'Roboto', monospace;
	font-weight: 400 !important;
}

.tertiaryFontItalic {
	font-family: 'Roboto', monospace;
	font-weight: 400 !important;
	font-style: italic !important;
}

.tertiaryFontMedium {
	font-family: 'Roboto', monospace;
	font-weight: 600 !important;
}

.tertiaryFontBold {
	font-family: 'Roboto', monospace;
	font-weight: 700 !important;
}

.mainContainer {
	padding: 60px 140px;
}
@media (min-width: 1366px) {
	.mainContainer {
		 /* padding-inline: 0; max-width: 1280px;  */
		 margin: 0 auto;
	}
}

@media (max-width: 1366px) {
	.mainContainer {
		padding: 0 60px;
	}
}

@media (max-width: 768px) {
	.mainContainer {
		padding: 0 30px;
	}
}

@media (min-width: 1366px) {
	.mainContainerHeight {
		height: 100vh;
	}
}

.btn {
	border-radius: 5px!important;
}

.containerDiv {
	padding-top: 30px;
	padding-bottom: 30px;
}

.headerSpacer {
	padding: 10px;
	display: block;
}

.noStyleBtn {
	background: none !important;
	border: none !important;
	padding: 0 !important;
	box-shadow: none !important;
}

.generalButton {
	background-color: #0050f0 !important;
	color: #ffffff !important;
	border: 1px solid #0050f0 !important;
	border-radius: 10px !important;
	width: 250px;
	padding: 10px 0 !important;
	font-size: 16px !important;
	/* font-family: "Source Sans Pro", sans-serif; */
	transition-duration: 0.4s;
}

.generalButton:hover {
	background-color: #0050f0 !important;
	color: #ffffff !important;
	transition-duration: 0.4s;
}

@media (max-width: 768px) {
	.generalButton {
		width: 100% !important;
		font-size: 14px !important;
	}
}

.generalButton:disabled {
	cursor: not-allowed !important;
	pointer-events: all;
	opacity: 0.65;
}

.bannerAlert {
	background-color: #0050f0 !important;
	color: #ffffff !important;
	margin-top: 10% !important;
	margin-bottom: 10% !important;
	text-align: center;
	font-size: 24px;
}

.maxWidth {
	width: 100% !important;
}

.redLinks {
	color: red !important;
	/* color: #0050f0 !important; */
	text-decoration: none !important;
	font-weight: 700 !important;
	font-size: 12px;
}

.generalLink {
	color: #0050f0;
	text-decoration: none !important;
	font-weight: 600;
	transition-duration: 0.4s;
}

.generalLink:hover {
	transition-duration: 0.4s;
}

/* Color */

.mainColor {
	color: #0050f0 !important;
}

.secondaryColor {
	color: #287785 !important;
}

.backgroundColor {
	color: #f8f9fc !important;
}

.invisible {
	opacity: 0 !important;
}

.opaque {
	opacity: 0 !important;
}

.required {
	color: red;
	font-size: 20px;
	font-weight: 900;
}

.fadeInandOut {
	animation: FadeAnimation 5s ease-in 0.2s forwards;
}

@keyframes FadeAnimation {
	0% {
		opacity: 1;
		visibility: visible;
	}

	100% {
		opacity: 0;
		visibility: hidden;
	}
}

/* Global Styles */

.col-xs-5ths,
.col-sm-5ths,
.col-md-5ths,
.col-lg-5ths,
.col-xl-5ths {
	position: relative;
	min-height: 1px;
	padding-right: 10px;
	padding-left: 10px;
}

.col-xs-5ths {
	width: 20%;
	float: left;
}

@media (min-width: 1366px) {
	.col-sm-5ths {
		width: 20%;
		float: left;
	}
}

@media (min-width: 992px) {
	.col-md-5ths {
		width: 20%;
		float: left;
	}
}

@media (min-width: 1200px) {
	.col-lg-5ths {
		width: 20%;
		float: left;
	}
}

@media (min-width: 1650px) {
	.col-xl-5ths {
		width: 20%;
		float: left;
	}
}

.boxShadow {
	-webkit-box-shadow: 0px 0px 77px -17px rgba(0, 0, 0, 0.75);
	-moz-box-shadow: 0px 0px 77px -17px rgba(0, 0, 0, 0.75);
	box-shadow: 0px 0px 77px -17px rgba(0, 0, 0, 0.75);
}

/* Scrollbar Section */

/* width */
::-webkit-scrollbar {
	width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
	border-radius: 50px;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #c9c9c9;
	border-radius: 50px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #ebebeb;
}

/* Change the white to any color ;) */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	box-shadow: 0 0 0 30px white inset !important;
	-webkit-box-shadow: 0 0 0 30px white inset !important;
	-webkit-appearance: none !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none !important;
	margin: 0;
}

input {
	/* -webkit-appearance: none !important; */
	/* -moz-appearance: none !important; */
}

/* Firefox */
input[type='number'] {
	-moz-appearance: textfield;
}

.hideDiv {
	animation: FadeAnimation 5s ease-in 0.2s forwards;
}

@keyframes FadeAnimation {
	0% {
		opacity: 1;
		visibility: visible;
	}

	100% {
		opacity: 0;
		visibility: hidden;
	}
}

.brandHrLine {
	background-color: #0050f0;
}

@media (max-width: 768px) {
	.hideMobile {
		display: none !important;
	}
}

.bold {
	font-weight: 700 !important;
}

.heavyBold {
	font-weight: 900;
}

.avenirBoldItalic {
	font-family: 'Avenir Next' !important;
	font-style: italic !important;
	font-weight: 900 !important;
}

.avenirBold {
	font-family: 'Avenir Next' !important;
	font-style: normal;
	/* font-weight: 900; */
}

.avenir {
	font-family: 'Avenir Next' !important;
	font-style: normal;
	font-weight: 400;
}

.arial {
	font-family: 'Arial' !important;
	font-style: normal;
	font-weight: 400;
}

/* Color */

.blue {
	color: #0051f3;
}

.grey {
	color: #b1b1b1;
}

.opaque {
	opacity: 0 !important;
}

@media (min-width: 768px) {
	.mobileLineBreak {
		display: none;
	}
}

/* Global Fonts */

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: 'Avenir Next' !important;
}

p {
	/* font-family: 'Arial'; */
	font-size: 16px;
}

a {
	font-family: 'Avenir Next Cyr';
}

ul,
li {
	font-family: 'Arial';
}
.searchBox {
	margin-top: 10px;
	margin-bottom: 10px;
}

.playerSearchInput {
	width: 100%;
	padding: 8px;
	font-size: 14px;
	border: 1px solid #ccc;
	border-radius: 4px;
}
