#fullHeight {
	height: 100vh;
	display: flex;
}
#loading {
	display: inline-block;
	width: 50px;
	height: 50px;
	border: 3px solid rgba(0, 80, 240, 0.3);
	border-radius: 50%;
	border-top-color: #0050f0;
	animation: spin 1s ease-in-out infinite;
	-webkit-animation: spin 1s ease-in-out infinite;
	margin: auto;
}

@keyframes spin {
	to {
		-webkit-transform: rotate(360deg);
	}
}
@-webkit-keyframes spin {
	to {
		-webkit-transform: rotate(360deg);
	}
}
