#menu {
	background-color: #000000 !important;
	border-bottom: 1px solid #353A7A;
}

@media (max-width: 768px) {
	#menu {
		padding-top: 15px;
		padding-bottom: 15px;
	}
}

.navbarContainer {
	display: flex;
	flex-wrap: inherit;
	align-items: center;
	justify-content: space-between;
}

@media (min-width: 768px) {
	.navbarContainer {
		width: 85%;
		padding-left: 15px;
		padding-right: 15px;
		margin: auto;
	}
}

@media (max-width: 768px) {
	.navbarContainer {
		width: 100%;
		padding-left: 15px;
		padding-right: 15px;
	}
}

#menu .logoLink {
	margin: auto;
	/* transform: translate(75%, 0%); */
	transform: translate(-175%, 0%);
}
  
@media (max-width: 768px) {
	#menu {
		background: #000000 !important;
	} 
}

#menu .logo {
	width: 150px;
	height: auto;
}

@media (min-width: 991.99px) {
	.logoLinkMobile {
		display: none;
	}
}
@media (max-width:991px){
	#menu .navbar-nav {
        max-height: calc(100vh - 30px);
        overflow-y: auto;
    }
}
 
#menu a.nav-link {
	color: #CCDAF9;
	font-size: .875rem;
	margin: auto;
	transition-duration: 0.4s;
	-webkit-transition-duration: 0.4s;
	/* font-family: 'Avenir Next'; */
}

#menu a.nav-link:hover {
	color: #0050f0;
	transition-duration: 0.4s;
	-webkit-transition-duration: 0.4s;
}

@media (min-width: 768px) {
	.navItemStyleSpan {
		border: 2px solid var(--bs-white);
		padding: 5px 30px;
		transition-duration: 0.4s;
		-webkit-transition-duration: 0.4s;
	}

	.navItemStyleSpan:hover {
		background-color: var(--bs-white);
		color: #0050f0;
		transition-duration: 0.4s;
		-webkit-transition-duration: 0.4s;
	}
}

.modalCloseBtnLink {
	display: block;
	text-align: right;
	padding-bottom: 15px;
	width: 25%;
	margin-right: 0;
	margin-left: auto;
}

.modalCloseBtn {
	margin-right: 0;
	margin-left: auto;
	width: 20px;
	height: auto;
}

.profileIconDiv {
	border: 1px solid var(--bs-white);
	border-radius: 50%;
	padding: 15px;
}

.profileIcon {
	width: 15px;
	height: 15px;
}

/* Login Section */

@media (min-width: 768px) {
	#loginModal {
		max-width: 50% !important;
	}
}

.loginDiv,
.forgotPwdDiv {
	background-color: var(--bs-white);
	border: 1px solid var(--bs-white);
	border-radius: 15px;
	/* min-height: 100%; */
	text-align: center;
	padding: 30px;
}

#loginModal > .modal-content {
	background: none;
	border: none;
}

#loginModal > .modal-content .modal-header {
	display: none;
}

.thirdPartySignInBtn {
	background-color: #f8f9fc;
	border: 1px solid #000000;
	color: #000000;
	padding: 15px;
	width: 100%;
	margin-bottom: 15px;
	border-radius: 7px;
}

.hrDiv {
	width: 100%;
	height: 20px;
	border-bottom: 3px solid grey;
	text-align: center;
}

.hrDiv > span {
	background-color: var(--bs-white);
	padding: 0 10px;
	margin-top: 7px;
	display: inline-block;
}

.hrStyle {
	width: 85%;
	border: 0;
	border-top: 1.5px solid #ccc;
	padding: 15px 0;
	margin: auto;
}

/* Mobile Toggle Menu */

.toggleMenu {
	border: none !important;
	box-shadow: none !important;
}

.toggleMenuDiv {
	width: 50px;
}

.toggleMenuLine {
	height: 5px;
	width: 100%;
	background-color: var(--bs-white);
	display: block;
}

.toggleMenuDiv span:nth-child(1),
.toggleMenuDiv span:nth-child(2) {
	margin-bottom: 5px;
}

.toggleMenu:not(.collapsed) .toggleMenuLine:first-child {
    transform: rotate(45deg);
    transform-origin: center;
    top: 15px;
    margin: 0;
	transition: all .2s ease-in-out;
} 
.toggleMenu:not(.collapsed) .toggleMenuLine:nth-child(2):not(.collapsed) {
    visibility: hidden;
}
.toggleMenu:not(.collapsed) .toggleMenuLine:nth-child(3):not(.collapsed) {
    transform: rotate(-45deg);
    transform-origin: center;
	transition: all .2s ease-in-out;
}

@media (max-width: 768px) {
	#menu .navbar-collapse {
		margin-top: 30px;
	}
	#menu .navbar-nav {
		text-align: center;
	}
}

#menu .facebookIcon {
	width: 12.5px;
	height: auto;
	margin-top: -5px;
}

#menu .twitterIcon {
	width: 20px;
	height: auto;
}

/* Subscription Section */

.subscriptionLogo {
	width: 60px;
	height: auto;
}

/* Banner Alert Section */

.bannerSubscriptionAlert {
	background-color: #0050f0 !important;
	color: var(--bs-white) !important;
	text-align: center;
	margin-bottom: 0 !important;
}

.bannerSubscriptionAlertText {
	font-size: 24px;
}

.bannerSubscribeButton {
	padding: 10px 30px;
	color: #0050f0;
	background-color: var(--bs-white);
	border: 1px solid var(--bs-white);
	text-decoration: none !important;
	border-radius: 5px;
	transition-duration: 0.4s;
	margin: 15px auto 0 auto;
	display: block;
	width: fit-content;
}

.bannerSubscribeButton:hover {
	color: var(--bs-white);
	background-color: transparent;
	transition-duration: 0.4s;
}

.subscriberPageSportToggleDiv {
	margin: auto;
}

.subscriberPageSportToggleLink {
	text-decoration: none !important;
	color: var(--bs-white) !important;
	font-size: 24px;
	margin-left: 10px;
	margin-right: 10px;
}

/* DropDown Menu */
.header_dropMenu button {
	color: #CCDAF9;
	font-size: .875rem; 
	text-transform: uppercase;
	font-family: 'Avenir Next Cyr';
}
.header_dropMenu button:hover, .header_dropMenu button:focus, .header_dropMenu button:active, .header_dropMenu button:visited, .header_dropMenu button.show  {
	background-color: transparent!important;
	border-color: transparent!important;
	color: var(--bs-white)!important;
}
.header_dropMenu .dropdown-menu {
	padding: 0;
	overflow: hidden;
}
.dropdown.show .dropdown-toggle::after{
	transform: rotate(180deg);
}
.header_dropMenu .dropdown-toggle::after {
	margin-left: .7rem; 
    border-right-width: 0.4em;
    border-left-width: .4em;
	border-top-width: .4em;
	position: relative;
	top: 2px;
}
.header_dropMenu .dropdown-menu a {
	font-weight: 600;
	font-size: .875rem;
	padding: 10px 1.2rem;
	border-bottom: 1px solid rgba(0,0,0, 0.2);
}
.header_dropMenu .dropdown-menu a:hover {
	background-color: #0050f0;
	color: var(--bs-white);
}
.header_dropMenu .dropdown-menu a:last-child {
	border-bottom: 0;
}