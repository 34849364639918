.bettingRow {
	align-items: center;
	padding: 15px 30px;
}

.bettingPredictionRow {
	padding: 30px;
}

@media (min-width: 768px) {
	.bettingPredictionRow {
		border: 5px solid #0050f0;
	}
}

.noBetColor {
	color: red;
}

.bettingInfoCentered {
	text-align: center;
}

.colAlignContentCenter {
	text-align: center;
}

.colAlignContentRight {
	text-align: right;
}

.bettingInfoDiv {
	display: flex;
	justify-content: space-between;
}

@media (min-width: 768px) {
	.bettingPredictionDiv {
		display: flex;
		justify-content: space-between;
	}
}

.bettingPredictionInfo {
	font-size: 20px;
	margin: 0;
}

@media (max-width: 768px) {
	.bettingPredictionInfo {
		text-align: center !important;
		margin-top: 30px;
	}
}

@media (min-width: 768px) {
	.bettingInfoTeamName {
		display: none;
	}
}

.bettingInfo {
	font-size: 20px;
	margin: 0;
}

.bettingInfoLeft {
	text-align: left;
}

.bettingInfoRight {
	text-align: right;
	margin-left: auto;
}

.matchupInfoHrSeparator {
	margin-top: 0;
}

.matchupPointSpreadLogo {
	width: 45px;
	height: auto;
}

.matchupLogo {
	width: 100px;
	height: auto;
	margin-right: 10px;
	margin-bottom: 10px;
}

.expertPicksHeader {
	margin: 0;
}

.sportsDataMatchupLogo {
	width: 100px;
	height: auto;
	margin-bottom: 10px;
}

.colAlignContentLeft {
	text-align: left;
}
