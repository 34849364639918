/* Science Section */

.aboutScienceRow {
	text-align: left;
}

@media (min-width: 1100px) {
	.aboutScienceRow {
		padding: 60px 0 0 0;
	}
}

.aboutScienceCol {
	height: 100%;
}

.aboutScienceColLeft {
	display: flex;
}

@media (max-width: 1100px) {
	.aboutScienceColLeft {
		padding: 60px 55px 0 55px;
	}
}

.aboutScienceDiv {
	margin: auto;
}

@media (min-width: 1100px) {
	.aboutScienceDiv {
		width: 75%;
	}
}

/* .aboutScienceColRight {
  background: url("../Dashboard/img/howItWorksNBA.png");
  background-size: 85%;
  background-repeat: no-repeat;
  background-position: center;
} */

.aboutScienceHeader {
	font-size: 60px;
}

@media (max-width: 1100px) {
	.aboutScienceHeader {
		font-size: 30px;
	}
}

.aboutScienceText {
	margin: 15px 0 0 0;
	font-size: 18px;
	line-height: 28px;
}

.aboutScienceSubHeader {
	font-size: 22px;
}

/* Latest Section */

@media (min-width: 1100px) {
	.aboutLatestSection {
		padding-top: 60px;
	}
	.aboutLatestRow {
		/* height: 40vh; */
		align-items: center;
		display: flex;
		flex-wrap: wrap;
	}
}

.aboutLatestCol {
	height: 100%;
	display: flex;
}

.aboutLatestColRight {
	display: flex;
}

@media (max-width: 1100px) {
	.aboutLatestColRight {
		padding: 60px 55px;
	}
	.aboutLatestColLeft {
		height: 300px;
	}
}

.aboutLatestDiv {
	margin: auto;
}

@media (min-width: 1100px) {
	.aboutLatestDiv {
		width: 75%;
	}
}

.aboutLatestColLeft {
	background: url('./img/aboutLatestImg.png');
	background-size: cover;
	background-repeat: no-repeat;
}

.aboutLatestHeader {
	font-size: 60px;
}

@media (max-width: 1100px) {
	.aboutLatestHeader {
		font-size: 30px;
	}
}

.aboutLatestText {
	margin: 15px 0 0 0;
	font-size: 18px;
	line-height: 28px;
	text-align: left;
}

.aboutLatestButton {
	display: inline-block;
	padding: 15px 20px;
	color: #ffffff;
	text-decoration: none !important;
	text-align: center;
	border: 1px solid #0050f0;
	background-color: #0050f0;
	font-size: 20px;
	margin: 30px 0 0 0;
}

.aboutLatestButton:hover {
	color: #0050f0;
	background-color: transparent;
}

/* About Information Section */

.aboutInfoDiv {
	/* display: flex;
  flex-wrap: wrap;
  align-items: center; */
}

.aboutCategoryImg {
	width: 75%;
	height: auto;
	margin: auto;
	display: block;
}

@media (min-width: 1100px) {
	.aboutInfoSection {
		padding: 60px 0 60px 0;
	}
	.aboutInfoDiv {
		width: 60%;
		margin: auto;
	}
}

/* .aboutInfoText {
  text-align: center;
  font-size: 20px;
  line-height: 32px;
} */

.aboutInfoText {
	margin: 15px 0 0 0;
	font-size: 18px;
	line-height: 28px;
}

@media (max-width: 1100px) {
	.aboutInfoSection {
		padding: 60px 45px;
	}
	.aboutInfoText {
		text-align: left;
	}
}

.aboutSubscribeText {
	text-align: center;
	font-size: 20px;
	line-height: 32px;
	padding-top: 30px;
	color: #0051f3;
}

@media (max-width: 1100px) {
	.aboutSubscribeText {
		text-align: left;
	}
}

/* Stats Info */

.aboutInfoStatsDiv {
	padding-top: 60px;
}

@media (max-width: 1100px) {
	.aboutInfoStatsDiv {
		padding-top: 30px;
	}
}

.aboutInfoStatsSubDiv p {
	margin: 0;
}

.aboutInfoStatsSubDiv {
	padding: 15px 0;
}

.aboutInfoStatsHeader {
	font-size: 22px;
	text-align: center;
	line-height: 30px;
}

@media (max-width: 1100px) {
	.aboutInfoStatsHeader {
		text-align: left;
	}
}

.aboutInfoStatsValue {
	color: #0050f0;
	font-size: 36px;
	font-weight: 700;
}

/* About Logo Section */

.aboutLogoDiv {
	text-align: center;
	background-color: #0050f0;
	padding: 60px 0;
}
.aboutFstaImg {
	width: 200px;
	height: auto;
}

.aboutFstaHeader {
	font-size: 28px;
	color: #ffffff;
}

.scienceDiv {
	text-align: left;
}

@media (min-width: 768px) {
	.scienceDiv {
		padding: 60px 60px 0 60px;
	}
}

@media (max-width: 768px) {
	.scienceDiv {
		padding: 30px;
	}
}

.aboutMatchupRow {
	padding: 100px 0 0 0;
}

.aboutMatchupCol {
	padding: 0 !important;
}

.aboutMatchupImg {
	width: 100%;
	height: auto;
	margin: auto;
}

/* Signup About Button */

.signupAboutButton {
	background-color: transparent;
	color: #ffffff;
	border: 1px solid #ffffff;
	background-color: #000000;
	margin: 30px auto 0 auto !important;
	border-radius: 0;
	font-size: 16px;
	padding: 15px 0;
	transition-duration: 0.4s;
	width: 200px;
	display: block !important;
}

.signupAboutButton:hover {
	color: #000000 !important;
	background-color: #ffffff !important;
	transition-duration: 0.4s;
}

@media (max-width: 768px) {
	.signupAboutButton {
		margin-top: 15px !important;
	}
}

.aboutVideoDiv {
	padding: 60px;
	width: 50%;
	margin: auto;
}

@media (max-width: 768px) {
	.aboutVideoDiv {
		width: 100%;
		padding: 60px 0;
	}
}

.aboutVideoHeader {
	padding-bottom: 30px;
}
