.generalCoverSection {
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	height: 60vh;
	display: flex;
}

.generalCoverDiv {
	margin: auto;
	text-align: center;
	position: initial;
	z-index: 999;
}

@media (min-width: 1367px) {
	.generalCoverDiv {
		width: 55%;
	}
}

@media (min-width: 813px) and (max-width: 1367px) {
	.generalCoverDiv {
		width: 60%;
	}
}

@media (max-width: 1100px) {
	.generalCoverDiv {
		padding: 0 30px;
	}
}

.generalCoverHeader {
	margin: 0;
	color: #ffffff;
	/* font-size: 50px; */
}

.generalCoverSubHeader {
	margin: 0;
	color: #ffffff;
	/* font-size: 50px; */
}

@media (min-width: 768px) {
	.generalCoverHeaderDiv {
		width: 75%;
		margin: auto;
	}
}

@media (max-width: 1100px) {
	.generalCoverHeader {
		font-size: 24px;
	}
	.generalCoverSubHeader {
		font-size: 18px;
	}
}

.generalCoverText {
	margin: 15px auto 0 auto;
	color: #ffffff;
	line-height: 28px;
}

@media (min-width: 768px) {
	.generalCoverText {
		width: 85%;
	}
}

.generalCoverDownArrowDiv {
	position: absolute;
	bottom: 15px;
	width: 100%;
	left: 0;
}

.generalCoverDownArrow {
	width: auto;
	height: 45px;
	transition-duration: 0.4s;
}

.generalCoverDownArrow:hover {
	height: 50px;
	transition-duration: 0.4s;
}

.generalSignupButton {
	margin-top: 30px;
}
