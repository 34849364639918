.gameMainSection {
	background-color: #f7f7f7;
	/* min-height: 100vh; */
}

@media (min-width: 768px) {
	.gameMainDiv {
		width: 85%;
		background-color: #ffffff;
		margin: auto;
		/* min-height: 100vh; */
		padding-bottom: 60px;
	}
}

@media (max-width: 768px) {
	.gameMainDiv {
		overflow-x: hidden;
	}
}

.gameBoxShadow {
	/* box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.75); */
	padding: 0 15px;
	margin-top: 75px;
}

@media (max-width: 768px) {
	.gameBoxShadow {
		padding: 0;
	}
}

/* Spread Header */

@media (max-width: 768px) {
	.gameSpreadHeaderRow {
		display: none !important;
	}
}

.gameSpreadHeaderCol {
	font-weight: 700;
}

.gameSpreadHeaderNoBetCol {
	font-weight: 700;
	/* padding: 0; */
	/* width: 100%; */
}

@media (min-width: 768px) {
	.gameSpreadHeaderCol {
		height: 60px;
		display: table;
	}
	.gameSpreadHeaderNoBetCol {
		background-color: #c71717;
		display: table;
		height: 60px;
	}
}

.teamShadowHighlight {
	-webkit-box-shadow: 0px 0px 77px -17px rgba(0, 0, 0, 0.75);
	-moz-box-shadow: 0px 0px 77px -17px rgba(0, 0, 0, 0.75);
	box-shadow: 0px 0px 77px -17px rgba(0, 0, 0, 0.75);
}

/* .gameSpreadHeaderRed {
  border: 3px solid #c71717 !important;
} */

.gameSpreadHeaderDiv {
	vertical-align: middle;
	color: #ffffff;
	padding: 0 20px;
}

.gameNoSpreadHeaderDiv {
	background-color: #c71717;
}

@media (max-width: 768px) {
	.gameTeamHeaderRow {
		margin: 0;
		padding-top: 20px !important;
	}
	.opaque {
		display: none !important;
	}
}

@media (min-width: 768px) {
	.gameSpreadHeaderDiv {
		display: table-cell;
	}
}

.gameSpreadHeaderSpread,
.gameSpreadHeaderDescription {
	margin: 0;
	font-size: 24px;
}

@media (max-width: 768px) {
	.gameSpreadHeaderSpread,
	.gameSpreadHeaderDescription {
		display: inline;
	}
	.gameSpreadHeaderSpread {
		float: right;
	}
}

@media (min-width: 768px) {
	.gameSpreadHeaderSpread {
		float: right;
	}
	.gameSpreadHeaderDescription {
		float: left;
	}
}

/* Team Header */

.gameTeamHeaderCol {
	background-color: #ffffff;
	/* height: 60px; */
	display: table;
	padding: 0;
	border: 1px solid rgba(222, 222, 222, 0.5);
	font-weight: 700;
	/* width: 100%; */
}

.gameTeamHeaderDiv {
	display: table-cell;
	vertical-align: middle;
}

@media (max-width: 768px) {
	.gameTeamHeaderDiv {
		display: flex;
	}
}

.gameTeamHeaderDivHome {
	/* float: right; */
}

.gameTeamHeaderSpread {
	margin: 0;
	/* padding: 30px 30px; */
	padding: 30px;
	height: 100%;
	font-size: 24px;
	color: #ffffff;
}

.gameTeamHeaderSpreadRight {
	margin: 0 0 0 auto;
}

@media (min-width: 768px) {
	.gameTeamHeaderSpread {
		display: none;
	}
}

.gameTeamHeaderLogoDiv {
	padding: 5px 30px;
	display: flex;
	align-items: center;
}

@media (max-width: 769px) {
	.gameTeamHeaderLogoAwayDiv {
		margin-left: auto;
	}
}

.gameTeamHeaderLogoRightDiv {
	justify-content: end;
}

@media (min-width: 768px) {
	.gameTeamLogo {
		max-width: 60px;
		max-height: 60px;
	}
}

@media (max-width: 768px) {
	.gameTeamLogo {
		max-width: 75px;
		height: auto;
	}
}

.gameTeamHeaderName {
	margin: 0;
	font-size: 24px;
}

@media (max-width: 768px) {
	.gameTeamHeaderName {
		font-size: 18px;
	}
}

.gameTeamHeaderNameAway {
	padding-left: 15px;
}

.gameTeamHeaderNameHome {
	padding-right: 15px;
}

/* Game Description */

.gameDescriptionCol {
	/* padding: 0; */
	background-color: #ffffff;
	border: 1px solid rgba(222, 222, 222, 0.5);
}

.gameDescription {
	padding: 30px 20px;
}

/* Game Legend */

.gameLegendRow {
	background-color: rgba(247, 247, 247, 0.5);
	position: relative;
	padding: 15px 30px;
	border: 1px solid rgba(222, 222, 222, 0.5);
}

@media (max-width: 768px) {
	.gameLegendRow {
		margin: 0;
	}
}

.gameLegenLabelText {
	font-size: 16px;
}

.gameLegenLabelTextDescription {
	padding-top: 10px;
}

.gameLegendRow p {
	margin: 0;
}

.gameLegendBox {
	width: 20px;
	height: 20px;
}

.gameLegendBoxPeak {
	background-color: #75af07;
}

.gameLegendBoxFair {
	background-color: #d6c605;
}

.gameLegendBoxPoor {
	background-color: #c71717;
}

.gameLegendStatusDiv {
	display: flex;
}

.gameLegendStatus {
	padding-left: 10px;
	font-weight: 700;
}

.gameLegendPercent {
	/* padding-left: 10px; */
	font-weight: 700;
	padding-left: 30px;
}

.gameLegendInfo {
	padding-top: 10px;
}

.gameLegendArrowCol {
	text-align: right;
	position: relative;
}

.gameLegendArrowLink {
	display: flex;
	align-items: center;
	justify-content: end;
	height: 100%;
}

.gameLegendArrow {
	width: 25px;
	height: auto;
	transition-duration: 0.6s;
}

.gameLegendArrowRotate {
	transform: rotate(180deg);
	transition-duration: 0.6s;
}

@media (max-width: 768px) {
	.gameLegendCol {
		padding: 10px 0;
	}
	.gameLegendArrowLink {
		display: none;
	}
}

/* Match Dropdown Panel */

.gameMatchupRow {
	/* min-height: 50vh; */
}

@media (max-width: 768px) {
	.gameMatchupRow {
		padding-bottom: 60px;
	}
}

.gameMatchupHeaderCol {
	padding: 0 30px;
}

.gameMatchupHeaderCol1 {
	border-right: 1px solid rgba(112, 112, 112, 0.09);
	height: 100%;
	/* min-height: 50vh; */
}

.gameMatchupHeaderCol2 {
	border-left: 1px solid rgba(112, 112, 112, 0.09);
	height: 100%;
	/* min-height: 50vh; */
}

.matchupPeak {
	background-color: #6ca204;
}

.matchupFair {
	background-color: #d6c605;
}

.matchupPoor {
	background-color: #c71717;
}

.matchMainDiv {
	padding: 0 15px 5px 15px !important;
}

@media (max-width: 768px) {
	.matchMainDiv {
		padding: 15px 30px 0 30px;
	}
}

.matchupHeader {
	/* float: right; */
	text-align: center;
	display: block;
	padding: 0 10px 0 0;
	font-size: 16px;
}

@media (max-width: 768px) {
	.matchupHeader {
		display: none;
	}
}

.matchupMobileHeader {
	/* float: right; */
	text-align: center;
	display: block;
	font-size: 20px;
	margin: 0;
	padding-bottom: 5px;
}

@media (min-width: 768px) {
	.matchupMobileHeader {
		display: none;
	}
}

.matchupViewAllDiv {
	/* min-height: 50vh; */
	/* text-align: right;
  padding-right: 60px; */
}

.matchupViewAll {
	text-decoration: none !important;
	color: #000000;
	display: block;
	padding: 0 10px 0 0;
	font-size: 16px;
}

@media (max-width: 768px) {
	.matchupViewAll {
		text-align: center;
	}
}

.matchupViewAll:hover {
	color: #0051f3;
	text-decoration: none;
	transition-duration: 0.4s;
}

/* @media (min-width: 768px) {
  .matchupViewAll {
    position: absolute;
    bottom: 0;
    right: 80px;
  }
}

@media (max-width: 768px) {
  .matchupViewAll {
    padding-top: 20px;
  }
} */

/* Matchup Button */

.matchMainDiv .dropdown {
	/* height: 30px; */
}

.matchMainDiv .dropdownMenuButton {
	width: 100%;
	text-align: left;
	padding: 0;
	border-radius: 0;
	border: 0;
	/* background-color: #151515; */
	background-color: transparent;
}

.matchMainDiv .dropdown-toggle::after {
	display: inline-block;
	width: 0;
	height: 0;
	margin-right: 15px;
	/* float: right; */
	margin-right: 30px;
	vertical-align: 0.255em;
	margin-top: 25px;
	content: '';
	border-top: 0.3em solid #ffffff;
	border-right: 0.3em solid transparent;
	border-bottom: 0;
	border-left: 0.3em solid transparent;
	font-size: 18px;
}

.matchMainDiv .dropdown-toggle::after {
	/* content: "\e114";
  display: inline-block;
  width: 0;
  height: 0;
  margin-right: 15px;
  float: right;
  margin-right: 30px;
  vertical-align: 0.255em;
  margin-top: 25px;
  font-size: 18px;
  color: #ffffff; */
}

.matchTooltip {
	margin-top: 15px;
	/* position: absolute;
  right: 0;
  top: 70%;
  transform: translateY(-70%); */
}

@media (max-width: 768px) {
	.matchTooltip {
		display: none;
	}
}

.matchMainDiv .dropdown-item {
	display: block;
	width: 100%;
	padding: 0 20px;
	clear: both;
	font-weight: 400;
	color: #212529;
	text-align: inherit;
	white-space: nowrap;
	background-color: transparent;
	border: 0;
}

.matchMainDiv .matchMenuDiv {
	width: 100%;
	text-align: left;
	padding: 0;
}

.matchMainDiv .matchupInfoDiv {
	display: inline;
	color: #ffffff;
}

.matchupTitleDropdownCol {
	background-color: #151515;
	padding-bottom: 5px;
}

.matchMainDiv .matchupTitle {
	margin-top: 15px;
	margin-left: 15px;
	font-size: 16px;
	display: inline-block;
	color: #ffffff;
}

.matchupTitleDropdownArrow {
	position: absolute;
	right: 30px;
	top: 60%;
	transform: translateY(-60%);
	transition-duration: 0.6s;
}

.matchupTitleDropdownArrowRotate {
	transform: rotate(180deg);
	top: 45%;
	transition-duration: 0.6s;
}

.matchMainDiv .matchupStatus {
	/* display: inline; */
	/* float: right; */
	font-size: 16px;
	padding: 15px 0;
	/* margin-right: -15px; */
	text-align: center;
	margin: 0;
}

/* Player Row */

.matchMainDiv .dropdown-menu {
	position: inherit;
	top: 100%;
	left: 0;
	z-index: 500 !important;
	display: none;
	float: left;
	min-width: 160px;
	/* padding: 0 55px 60px 0; */
	padding: 0 0 30px 0;
	/* margin: 2px 0 0; */
	font-size: 14px;
	text-align: left;
	list-style: none;
	background-color: #fff;
	-webkit-background-clip: padding-box;
	background-clip: padding-box;
	border: none;
	border-radius: 0;
	-webkit-box-shadow: none;
	box-shadow: none;
}

.matchMainDiv .dropdown-toggle::after {
	display: none;
}

.dropdown-menu.show {
	display: block !important;
}

.matchPlayerItem {
	/* padding: 10px 0; */
}

@media (min-width: 768px) {
	.matchPlayerItemRow {
		display: flex;
	}
}

.matchPlayerItemCol {
	padding: 10px 0;
}

.matchPlayerInfoDiv {
	padding-left: 30px;
}

@media (max-width: 1367px) {
	.matchPlayerInfoDiv {
		padding: 0 10px;
		text-align: left;
	}
	.matchPlayerInfoTopDiv {
		text-align: center !important;
	}
}

.matchPlayerInfoDiv p {
	margin: 0;
}

.matchPlayerInfo {
	padding-left: 5px;
}

@media (max-width: 768px) {
	.matchPlayerInfo {
		display: block;
		padding-left: 0;
	}
}

.matchPlayerInfoNameTop {
	font-size: 18px;
}

.matchPlayerImg {
	width: 80px;
	height: auto;
	/* margin-top: 0;
  margin-bottom: auto; */
}

.matchPlayerDiv {
	padding-bottom: 15px;
}

@media (max-width: 768px) {
	.matchPlayerDiv {
		margin: auto;
		width: 100%;
	}
	.matchPlayerImg {
		width: 65px;
		height: auto;
	}
}
.matchPlayerHpfStatusCol {
	background-color: #f5f5f5;
	position: relative;
}

.matchPlayerHpfStatusDiv {
	/* background-color: #b1b1b1; */
}

.matchPlayerViewAllDropdown {
	text-decoration: none;
	color: #000000;
}

@media (max-width: 768px) {
	.matchPlayerHpfStatusViewCol {
		padding-top: 60px;
		background-color: transparent !important;
	}
}

.matchPlayerViewAllDropdown:hover {
	text-decoration: none;
	color: #0051f3;
	transition-duration: 0.4s;
}

.matchPlayerHpfStatus {
	text-align: center;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

/* Full Roster */

.routerLinkMatchup {
	text-decoration: none !important;
	color: #000000;
	transition-duration: 0.4s;
}

.routerLinkMatchup:hover {
	color: #0051f3;
	transition-duration: 0.4s;
}

.fullRosterHpf {
	padding-top: 30px;
	font-size: 16px;
}

.fullRosterRow {
	display: flex;
	padding: 15px 0;
	font-size: 13px;
}

@media (min-width: 768px) {
	.fullRosterRow {
		height: 125px;
	}
}

@media (max-width: 768px) {
	.fullRosterRow {
		text-align: center;
	}
}

@media (max-width: 768px) {
	.fullRosterInfo {
		text-align: center;
		display: flex;
		justify-content: center;
	}

	.matchPlayerLabel {
		margin-right: 5px !important;
	}
}

.fullRosterCol {
	margin: auto;
}

.fullRosterInfo:nth-child(1) {
	padding: 0 0 0 20px;
}

.fullRosterRowDiv p {
	margin: 0;
}

.fullRosterImg {
	width: 80px;
	height: auto;
}

.fullRosterViewAll {
	background-color: #f5f5f5;
	display: block;
	padding: 15px 0;
	width: 150px;
	text-align: center;
	margin: auto 0 auto auto;
	color: #000000;
	text-decoration: none !important;
}

.fullRosterViewAll:hover {
	background-color: transparent;
	color: #0051f3;
	transition-duration: 0.4s;
}

.scheduleLinkCol {
	text-align: center;
}

@media (min-width: 1450px) {
	.scheduleLinkCol {
		padding: 0 60px;
		height: 100px;
	}
}

@media (max-width: 768px) {
	.scheduleLinkCol {
		text-align: center;
	}
	.scheduleLinkDiv {
		border-bottom: 2px solid #c9c9c9;
	}
}

.scheduleLinkTeam {
	text-decoration: none !important;
	font-size: 20px;
	color: #000000;
	display: block;
	margin: 10px auto;
}

@media (max-width: 1450px) {
	.scheduleLinkTeam {
		font-size: 16px;
	}
}

.scheduleLink {
	text-decoration: none !important;
	font-size: 20px;
	color: #000000;
	display: block;
	margin: 5px auto;
}

.scheduleLink:hover,
.scheduleLinkTeam:hover {
	/* color: blue; */
	/* cursor: pointer; */
	transition-duration: 0.4s;
}

.topFourPlayerRow {
	padding: 15px 0;
}

.topFourPlayerCol {
	padding-left: 30px;
}

.viewTopFourPlayer {
	text-decoration: none !important;
	color: #000000;
	padding: 15px auto;
	font-size: 16px;
}

.matchupHeaderTopMobile {
	text-align: center;
}

@media (min-width: 768px) {
	.matchupHeaderTopMobile {
		display: none;
	}
}

.hrStyleMobile {
	width: 85%;
	border: 0;
	border-top: 1.5px solid #ccc;
	padding: 5px 0;
}

@media (min-width: 768px) {
	.hrStyleMobile {
		display: none;
	}
}
